import { Flex, Text, Skeleton } from "@chakra-ui/react"
import { useContext } from "react"
import { motion } from "framer-motion"

import banner_l from '../../media/banner_l.png'
import banner_r from '../../media/banner_r.png'
import VPContext from "../others/VPContext"
import { UITheme } from "../others/colors"

function Hero() {
    const viewPort = useContext(VPContext)
    
    const bannerStyle = {
        maxHeight:'100%',
        paddingLeft:viewPort?'35px':'10px',
        paddingRight:viewPort?'35px':'10px',
        paddingTop:viewPort?'35px':'10px',
    }
    
    function TextC() {
        const headingStyle = {
            fontSize:viewPort?'3.3em':'2em',
            fontWeight: '700',
            color: UITheme(!viewPort).navBar,
        }
        const subHeadingStyle = {
            fontSize:viewPort?'3vw':'7.5vw',
            fontWeight: '500',
            color:'black',
            textWrap: 'nowrap',
            fontFamily: 'Allura',
        }
        return (
            <Flex style={{flexDirection:'column', alignItems:'center', justifyContent:"center"}}>
                <Text style={headingStyle}>
                    getKarma
                </Text>
                <Text style={subHeadingStyle}>
                    more than just a delivery app
                </Text>
            </Flex>
        )
    }

    function Mascot(props) {
        const sideContainer = {
            width:'100%',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: props.r?'flex-end':'flex-start',
        }
        return (
            <Skeleton isLoaded={props.mascot} style={sideContainer}>
                <img style={{objectFit:'contain'}} src={props.mascot} alt="mascot" />
            </Skeleton>
        )
    }

    return (
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1, transition:{duration:1}}}
        style={{height: viewPort?'35%':'fit-content',
            borderRadius:'25px',
            background:'linear-gradient(45deg, rgb(71, 142, 126),transparent, rgb(226, 151, 197))',
            margin:'20px'
        }}>
            <Flex style={bannerStyle}>
                <Mascot mascot={banner_l} />
                {!viewPort?'':<TextC/>}
                <Mascot r mascot={banner_r} />
            </Flex>
            {viewPort?'':<TextC/>}
        </motion.div>
    )
}

export default Hero