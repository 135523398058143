import { Text, Box, Divider, Flex, Switch, FormControl, FormLabel, Skeleton, Input, useToast, Button } from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MainContainer from "../UI/MainContainer";
import VPContext from "../others/VPContext";
import postData from '../../backend/postData'
import vibFn from "../others/vibration";
import { faPenToSquare, faKey } from "@fortawesome/free-solid-svg-icons";

export default function MyAccount() {

    const [autoAcc, setAutoAcc] = useState()
    const [userData, setUserData] = useState()
    const [oldPhone, setOldPhone] = useState()

    const viewPort = useContext(VPContext)
    const toast = useToast()
    
    function handleChange(e) {
        setUserData({ ...userData, [e.target.id]: e.target.value });
    }

    useEffect(()=>{
        const fetchUserData = async()=>{
            const response = await postData('/api/userdata')
            if(response.status===200) {
                    setUserData(response.data)
                    setOldPhone(response.data.phone)
                    setAutoAcc(response.data.autoAccept)
            }
            else{
            vibFn(4)
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while fetching data, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })}
        }
        fetchUserData()
    },[toast])

    async function handlePreference1() {
        const response = await postData('/api/updatepref1', {autoAccept: !autoAcc})
        if(response.status===200) {
            toast({
                title: 'Preference Saved',
                description:
                autoAcc?
                "Users will now require your approval to deliver your items..."
                :'Users can now accept your delivery requests without your approval...',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            setAutoAcc(!autoAcc)
        }
        else
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while updating preference, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    async function handleSubmit() {
        vibFn(3)
        const payload = {
            phone: userData.phone,
            name: userData.name
        }
        const response = await postData('/api/updateuserdata', payload)
        if(!response) { 
            vibFn(4)
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while saving data, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        else {
            toast({
                title: 'Info Updated !',
                description: "Your personal information has been updated and should take effect soon...",
                status: 'success',
                duration: 2000,
                isClosable: true,
            })
        }
    }

    function Setting(props) {
        const handleChange = ()=>{
            vibFn(2)
            setTimeout(()=>{
                props.saveFn(!props.status)                
            },80)
        }

        const containerStyle = {
            width: viewPort?'80%':'100%',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

        const textStyle = {
            fontSize:viewPort?'medium':'large',
            margin: '0',
        }
        const textStyle2 = {
            fontSize:viewPort?'small':'medium',
            color: 'gray',
        }
        return (
            <FormControl>
                <Flex style={containerStyle}>
                    <Flex flexDirection={'column'} width={'100%'}>
                    <FormLabel style={textStyle} htmlFor='switch1' >{props.text}</FormLabel>
                    <Text style={textStyle2}>{props.caption}</Text>
                    </Flex>
                    <Switch id='switch1' colorScheme="green" size={viewPort?'md':'lg'} defaultChecked={props.status} onChange={handleChange} />
                </Flex>
            </FormControl>
        )
    }

    function Section(props) {
        const headingStyle = {
            fontSize:'x-large',
            color: 'gray',
            fontWeight: '600',
            textWrap: 'nowrap'
        }
        return (
                <Flex alignItems={'center'} gap='10px' >
                    <Text style={headingStyle} >{props.heading}</Text>
                    <Divider border='1px solid gray'/>
                </Flex>
        )
    }

    const sectionStyle = {
        padding: '10px',
    }

    return (
        <MainContainer heading='My Account'>
            <br />
            <Section heading='Preferences' />
                <Box style={sectionStyle}>
                    <Skeleton isLoaded={userData}>
                    <Setting 
                    text='Require delivery person approval (Slow Delivery)'
                    caption='Your phone number will be hidden from unauthorized users'
                    status={!autoAcc} saveFn={handlePreference1} />
                    </Skeleton>
                </Box>
            <Section heading='Update Info'/>
                <Box style={sectionStyle}>
                    <Skeleton isLoaded={userData}>
                        <UpdateForm oldPhone={oldPhone?oldPhone:{}} userData={userData?userData:{}} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </Skeleton>
                    <br />
                    <Flex style={{width:'flex', justifyContent:'center'}}>
                    <Link to='/resetpw'>
                    <Button onClick={()=>vibFn(1)} colorScheme="blue" rightIcon={<FontAwesomeIcon icon={faKey} />}>Update Password</Button>
                    </Link>
                    </Flex>
                </Box>
        </MainContainer>
    )
}



function UpdateForm(props) {

    const viewPort = useContext(VPContext)
    
    const formStyle = {
        input: {
            boxSizing:'border-box', width:'100%', textAlign:'center'
        },
        label : {
            textWrap:'nowrap', boxSizing:'border-box', width:'50%', textAlign:viewPort?'right':'center'
        },
        flex : {
            alignItems: 'center', justifyContent: 'space-evenly', flexDirection: viewPort?'row':'column',
            paddingTop:"10px", paddingBottom: '10px'
        }
    }

    return (
        <>
        <FormControl>
            <Flex style={formStyle.flex}>
                <FormLabel htmlFor="f_username" 
                style={formStyle.label}
                >
                    Roll Number : </FormLabel>
                <Input id='f_username' style={formStyle.input} value={props.userData?props.userData.username:""} isDisabled={true}/>
            </Flex>
            <Flex style={formStyle.flex}>
                <FormLabel htmlFor="f_email" 
                style={formStyle.label}
                >
                    Email : </FormLabel>
                <Input id='f_email' style={formStyle.input} value={props.userData?props.userData.email:""} isDisabled={true}/>
            </Flex>
            <Flex style={formStyle.flex}>
                <FormLabel htmlFor="name" 
                style={formStyle.label}
                >
                    Name : </FormLabel>
                <Input isDisabled onChange={props.handleChange} id='name' style={formStyle.input} value={props.userData.name}/>
            </Flex>
            <Flex style={formStyle.flex}>
                <FormLabel htmlFor="phone" 
                style={formStyle.label}
                >
                    Phone Number : </FormLabel>
                <Input onChange={props.handleChange} type={'number'} id='phone' style={formStyle.input} value={props.userData.phone}/>
            </Flex>
            <Flex width={'100%'} justifyContent={'center'} padding={'10px'}>
                <Button isDisabled={Number(props.userData.phone)===Number(props.oldPhone)} colorScheme="green" onClick={props.handleSubmit} rightIcon={<FontAwesomeIcon icon={faPenToSquare} />}>Update Info</Button>
            </Flex>
        </FormControl>
        </>
    )
}
