import { Box, Flex, Text, useDisclosure, Modal, ModalOverlay, ModalBody, ModalContent, ModalHeader, 
    ModalCloseButton, ModalFooter, Button, Spinner, useToast } from '@chakra-ui/react'

import { Stepper, Step, StepIndicator, StepSeparator, StepTitle, StepDescription, Divider, Skeleton } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faHouseUser, faUser, faClock, faIndianRupeeSign, faCubes, faIdCard, faPersonRunning } from '@fortawesome/free-solid-svg-icons' 

import postData from '../../backend/postData'
import vibFn from '../others/vibration'
import { useEffect, useState } from 'react'
import { modalStyle } from '../others/style'

function ViewRequest(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()

    const [userData, setUserData] = useState()

    async function acceptRequest() {
        vibFn(1)
        const response = await postData('/api/acceptRequest', {id:props.req._id})
        if(response.status === 200)
        toast({
            title: 'Request Accepted !',
            description: "Continue in My Errands page ...",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
        else 
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while accepting request, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
        props.fetchagain()
        onClose2()
    }

    useEffect(()=>{
        const fetchUser = async()=>{
            const fetchedUser = await postData('/api/userdataByIdForReq', {id: props.req.userid, requestId: props.req._id})
            if(fetchedUser.status === 200) setUserData(fetchedUser.data)
            else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while fetching data, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        fetchUser()
    },[props.req.userid, props.req._id, toast])

    useEffect(onOpen,[onOpen])

    const onClose2 = ()=> {vibFn(1);onClose(); props.onclose()}

    const messageStyle={
        fontWeight:'500',
        color:'gray',
        textAlign:'center',
        fontStyle:'italic'
    }

    function formatMsToHoursAndMinutes(ms) {
        ms -= Date.now()
        if(ms<0) return 'Time finished'
        const totalMinutes = Math.floor(ms / 60000);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        const formattedTime = `${hours}h ${minutes}min`;
        return formattedTime;
    }

      function DataSpread(props) {
        return (
            <Flex flexDirection={'row'}>
            <Text style={{boxSizing:'border-box', width:'100%',
            color:'gray', fontWeight: '500', fontSize:'large'}}>{props.left}</Text>
            -
            <Text style={{boxSizing:'border-box', width:'100%', textAlign:'right'}}>{props.right}</Text>
            </Flex>
        )
      }

    return (
        <>
            {/* <Button onClick={onOpen} >View</Button> */}
            <Modal isOpen={isOpen} onClose={onClose2} isCentered motionPreset='slideInBottom'>
                <ModalOverlay style={{backdropFilter:'blur(5px)'}} />
                <ModalContent style={modalStyle()}>
                <ModalHeader textAlign={'center'}>View Request</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Text>{JSON.stringify(props.req)}</Text> */}
                    <Stepper colorScheme='blue' index={'2'}>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faLocationDot} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.req.location}</StepTitle>
                                <StepDescription>Pickup Location</StepDescription>
                            </Box>
                        </Step>
                        <StepSeparator/>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faHouseUser} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.req.hostel}</StepTitle>
                                <StepDescription>Delivery Location</StepDescription>
                            </Box>
                        </Step>
                    </Stepper>
                    <br />
                    <Divider/>
                    <br />
                    <Box>
                        <DataSpread left={<><FontAwesomeIcon icon={faCubes} /> Item</>} right={props.req.item} />
                        <Skeleton isLoaded={userData}>
                        <DataSpread left={<><FontAwesomeIcon icon={faUser} /> Requested By</>} right={userData?userData.name:<Spinner/>} />
                        </Skeleton>
                        <Skeleton isLoaded={userData}>
                        <DataSpread left={<><FontAwesomeIcon icon={faIdCard} /> Roll Number</>} right={userData?userData.username:<Spinner/>} />
                        </Skeleton>
                        <DataSpread left={<><FontAwesomeIcon icon={faIndianRupeeSign} /> Cost</>} right={props.req.cost} />
                        <DataSpread left={<><FontAwesomeIcon icon={faClock} /> Time</>} right={formatMsToHoursAndMinutes(props.req.time)} />
                    </Box>
                    <br />
                    {
                        props.req.payMode?
                        <Text style={messageStyle}>Earn ₹{Number(props.req.payMode)} upon fulfillment of this delivery...</Text>
                        :
                        <Text style={messageStyle}>Earn 1 karma credit upon fulfillment of this delivery...</Text>
                    }
                </ModalBody>

                <ModalFooter>
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width='100%'>
                        <Flex width='100%' justifyContent='space-evenly' >
                            <Button colorScheme='blackAlpha' mr={3} onClick={onClose2}>
                                Close
                            </Button>
                            <Button colorScheme='green' onClick={acceptRequest}
                            rightIcon={<FontAwesomeIcon icon={faPersonRunning} />}>
                                Accept
                            </Button>
                        </Flex>
                        <Text style={messageStyle}>{!userData?'':(
                            userData.autoAccept?''
                            :'The requester will first approve your delivery...'
                        )}</Text>
                    </Flex>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ViewRequest