export default function vibFn(mode) {
    //1 for touch
    //2 for switch
    //3 for done (saving info etc)
    //4 for finished (logout, withdraw etc)
    if(window.navigator && window.navigator.vibrate)
    {
        switch (mode){
            case 1:
                window.navigator.vibrate(15)
                break;
            case 2:
                window.navigator.vibrate([10,200,25])
                break;
            case 3:
                window.navigator.vibrate([150,100,100])
                break;
            case 4:
                window.navigator.vibrate(500)
                break;
        }
}
}