import { useEffect, useContext } from "react"
import { useNavigate } from "react-router"

import { Center, Flex, Text } from "@chakra-ui/react"

import Lottie from "lottie-react"
import ballBounce from '../../media/animations/ballBounce.json'
import VPContext from "../others/VPContext"

export default function QRedirect() {
    const viewPort = useContext(VPContext)
    const navigate = useNavigate()

    function handleQRRedirect() {
        if(window.location.search){
            const queries = window.location.search.split('?')[1].split('&')
            const querieSet = {}
            queries.forEach((querie)=>{
              querieSet[querie.split('=')[0]] = querie.split('=')[1].replace(/\+/g, ' ')
            })
            if(querieSet.hasOwnProperty('qrplc'))
                window.sessionStorage.setItem('qrplc', querieSet.qrplc)
    
        }
    }

    async function handleLoginCheck() {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/validateToken', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            })
            if (response.status === 200) {
                const validity = await response.json()
                if(!validity) return false
                if(!validity.validity) return false
                return true
            }
            else return false
        }
        catch(e) {return e}
    }

    handleQRRedirect()

    useEffect(()=>{
        const processRedirect = async()=>{
            const response = await handleLoginCheck()
            if(response) navigate('/places')
                else navigate('/login')
        }
        processRedirect()
    })

    return (
        <Center height={'100vh'}>
            <Flex style={{flexDirection:"column", alignItems:'center', justifyContent:'center'}}>
            <Center style={{width:viewPort?'40vw':'100vw'}}>
                <Lottie animationData={ballBounce} />
            </Center>
            <Text style={{fontSize:"x-large", fontWeight:'700'}}>Getting you there...</Text>
            </Flex>
        </Center>
    )
}