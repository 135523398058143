import { Box, Flex, Text, Skeleton, useToast } from '@chakra-ui/react'
import { useEffect, useState, useContext } from 'react'
import {
    Accordion, AccordionItem, AccordionIcon, AccordionButton,
    AccordionPanel, Spinner
} from '@chakra-ui/react'
import Lottie from 'lottie-react'

import postData from '../../backend/postData'
import VPContext from '../others/VPContext'
import squareShuffle from '../../media/animations/squareShuffle.json'
import hungry from '../../media/animations/hungry.json'
import emptyBox from '../../media/animations/emptyBox.json'
import vibFn from '../others/vibration'

import MainContainer from '../UI/MainContainer'
import { Cards1, Cards2, Cards3 } from '../elements/cards'
import { RequestItemButton } from '../elements/accentButtons'


function MyRequests() {

    const [requests, setRequests] = useState()
    const toast = useToast()
    const viewPort = useContext(VPContext)

    async function fetchMyRequests() {
        const response = await postData('/api/myrequests')
        if(response.status == 200) setRequests(response.data.requests)
        else
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while fetching data, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    useEffect(()=>{
        fetchMyRequests()
    },[])

    function filterFn(reqs, type){
        return reqs.filter((req)=>req.status == type)
    }

    function NoReqFound(props){
        const messageStyle = {
            color: 'gray',
            fontSize: 'medium',
            fontWeight: '600'
        }
        return (
            <>
            <Flex flexDirection={viewPort?'row':'column'} alignItems={'center'} justifyContent={'center'}>  
                <Flex backgroundImage='radial-gradient(circle at center, rgba(138,141,143,0.25),transparent, transparent)' width={props.small?'15vw':(viewPort?'20vw':'30vw')}>
                    <Lottie animationData={props.anim} />
                </Flex>
                <Flex flexDirection={'column'} gap='10px' alignItems={'center'} justifyContent={'center'}>
                    <Text style={messageStyle}>{props.text}</Text>
                {
                    !props.button?'':
                    <RequestItemButton/>
                }
                </Flex>
            </Flex>
            </>
        )
    }

    return (
        <MainContainer heading='My Requests' >
            <br /><br />
            <Box>
                <Text fontWeight={'500'}>
                    Waiting requests
                </Text>
                {(requests&&(!filterFn(requests, 'free').length && !filterFn(requests, 'awaited').length))?
                <NoReqFound small text='No requests waiting...' anim={squareShuffle} />:''
                }
                {requests ? filterFn(requests, 'free').map((request, key)=>(
                    <Cards3 type='myRequests' key={key} fetchagain={async()=> await fetchMyRequests()} data={request} />
                )):<Skeleton height={'50px'} />}
                {requests ? filterFn(requests, 'awaited').map((request, key)=>(
                    <Cards2 type='myRequests' key={key} fetchagain={async()=> await fetchMyRequests()} data={request} />
                )):<Skeleton height={'50px'} />}
                <br />
                <Accordion defaultIndex={[0]}>
                    <AccordionItem>
                        <AccordionButton onClick={()=>vibFn(2)} style={{color:'black', justifyContent:'space-between'}}>
                            <Text fontWeight={'500'}>
                                Ongoing Requests
                            </Text>
                            <AccordionIcon/>
                        </AccordionButton>
                        <AccordionPanel>
                            {requests ? (![...filterFn(requests, 'completing'),...filterFn(requests, 'busy')].length ? 
                            <NoReqFound button text='You do not have any ongoing requests...' anim={hungry} />:
                            [...filterFn(requests, 'completing'),...filterFn(requests, 'busy')].map((request, key)=>(
                                <Cards1 type='myRequests' key={key} fetchagain={async()=> await fetchMyRequests()} data={request} />
                            ))):<Skeleton height={'200px'} />}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton onClick={()=>vibFn(2)} style={{color:'black', justifyContent:'space-between'}}>
                            <Text fontWeight={'500'}>
                                Completed Requests
                            </Text>
                            <AccordionIcon/>
                        </AccordionButton>
                        <AccordionPanel>
                            {requests ? (!filterFn(requests,'complete').length ? <NoReqFound text='Your requests have not been fulfilled in the past...' anim={emptyBox} />:
                            filterFn(requests, 'complete').map((request, key)=>(
                                <Cards1 type='myRequests' key={key} fetchagain={async()=> await fetchMyRequests()} data={request} />
                            ))):<Skeleton height={'200px'} />}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </MainContainer>
    )
}

export default MyRequests