const places = {
    hostels: [
        {
            // code: 'bh1',
            name: 'Boys Hostel 1'
        },
        {
            // code: 'bh2',
            name: 'Boys Hostel 2'
        },
        {
            name: 'Boys Hostel 3'
        },
        {
            name: 'Boys Hostel 4'
        },
        {
            name: 'Boys Hostel 5'
        },
        {
            name: 'Boys Hostel 6'
        },
        {
            name: 'Boys Hostel 7'
        },
        {
            name: 'Boys Hostel 7e'
        },
        {
            name: 'MBH - A'
        },
        {
            name: 'MBH - B'
        },
        {
            name: 'MBH - F'
        },
        {
            name: 'Girls Hostel 1'
        },
        {
            name: 'Girls Hostel 2'
        },
        {
            name: 'MGH'
        },
    ],
    locations: [
        {
            name: 'Main Gate (Delivery)'
        },
        {
            name: 'The Snackers'
        },
        {
            name: 'Night Canteen'
        },
        {
            name: 'NesCafe'
        },
        {
            name: 'Yadav Canteen'
        },
        {
            name: 'Campus Cafe'
        },
        {
            name: 'Sai Juice Bar'
        },
        {
            name: 'Rim Jim Bakery'
        },
        {
            name: 'Verka Milk Booth'
        },
        {
            name: 'Khurana General Store'
        },
        {
            name: 'NITJ Book Shop'
        },
        {
            name: 'Sachdeva Enterprises'
        },
        {
            name: 'Dispensary'
        }
    ]
}

module.exports = places