import { Button, Center, Text, Flex, VStack, useToast, FormControl, FormLabel, Input, Divider, } from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

import MainContainer from "../UI/MainContainer";

import postData from "../../backend/postData";
import VPContext from "../others/VPContext";
import vibFn from "../others/vibration";
import locked from '../../media/animations/locked.json'

export default function Verify() {
    const toast = useToast()
    const [otp, setOtp] = useState(false)
    const [changeEmail, setChangeEmail] = useState(false)

    async function requestEmail() {
        vibFn(3)
        const response = await postData('/api/otpMail')
        console.log('response is', response)
        if(response.status===200){
            toast({
                title: 'Email Sent.',
                description: "Kindly check your inbox and follow the steps to proceed...",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
            setOtp(true)
        }
        else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while generating email, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
    }

    function OTPForm() {
        const viewPort = useContext(VPContext)
        const [OTP, setOTP] = useState()

        async function handleSubmit() {
            vibFn(3)
            if (!OTP)
                toast({
                    title: 'Enter OTP !',
                    description: "Kindly enter the OTP sent to your college email...",
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            else {
                const response = await postData('/api/verifyOTP', {otp:OTP})
                if(response.status===200){
                    if(response.data.status==='complete'){
                        toast({
                            title: 'Email Verified.',
                            description: "Your email has been verified successfully...",
                            status: 'success',
                            duration: 4000,
                            isClosable: true,
                        })
                        vibFn(1)
                        window.location.href='/'
                    } else if (response.data.status==='expired'){
                        toast({
                            title: 'OTP not valid.',
                            description: "The OTP entered is invalid or expired...",
                            status: 'warning',
                            duration: 4000,
                            isClosable: true,
                        })
                    }
                }
                else {
                    toast({
                        title: 'Error Encountered.',
                        description: "Error encountered while verifying OTP, please try again later...",
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    })
                }
            }
        }
        return (
            <motion.div
            initial={{opacity:0}}
            animate={{opacity:1, transition:{duration:1}}}
            style={{width:'100%', display:'flex', justifyContent:"center", flexDirection:"column", alignItems:'center',gap:'20px'}}
            >
            <Divider/>
            <FormControl style={{width:viewPort?'40%':'60%'}}>
                <Input
                type='number'
                value={OTP} 
                placeholder='6 digit OTP'
                onChange={(e)=>setOTP(e.target.value)}
                style={{textAlign:'center'}}
                />
                <br /><br />
                <Flex width={'100%'}>
                <Button
                onClick={handleSubmit}
                margin='auto'
                colorScheme="green"
                >Submit</Button>
                </Flex>
            </FormControl>
            </motion.div>
        )
    }

    function ChangeEmail(props) {
        const toast = useToast()
        const [email, setEmail] = useState('')
        const [authenticity, setAuthenticity] = useState(0)
        const viewPort = useContext(VPContext)

        useEffect(()=>{
            function cleanMail() {
                function hasCapitalLetters(str) {
                    return /[A-Z]/.test(str);
                }
                if(email.includes(' ') || hasCapitalLetters(email))
                setEmail((String(email).toLowerCase()).replace(/\s/g, ''))
            }
            cleanMail()
            const Assfc = async ()=>{
                const response = await postData('/api/checkUpdateEmail', {
                    mail: email
                })
                if(response.status === 200) {
                    if(response.data.status==='verified') {
                        setAuthenticity(1)
                    }
                    else if(response.data.status==='INV') {
                        setAuthenticity(2)
                    }
                    else if(response.data.status==='NA') {
                        setAuthenticity(3)
                    }
                    else if(response.data.status==='CURR') {
                        setAuthenticity(4)
                    }
                }
                else if (response.status===401) setAuthenticity(0)
                else {
                    toast({
                        title: 'Error Encountered.',
                        description: "Error encountered while checking credentials, please try again later...",
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    })}
            }
            if(!email) setAuthenticity(0)
            if(email) Assfc()
        },[email, toast])

        const handleSubmit = async()=>{
            const response = await postData('/api/updateEmail', {email: email})
            if(response.status===200) {
                toast({
                    title: 'Email Updated.',
                    description: "Your Email has successfully been updated...",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })
                props.closeSection()
            }
            else {
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while updating Email, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            }
        }
        
        const authenticityMessages = [
            'Fill Email',
            'College Email matched.',
            'College Email did not match.',
            'Kindly enter correct Roll number.',
            'This email is currently saved as your email.'
        ]
        return (
            <motion.div
            initial={{opacity:0}}
            animate={{opacity:1, transition:{duration:1}}}
            style={{width:viewPort?'50%':"80%",
                backgroundColor:'rgba(0,0,0,0.02)',
                border:'1px solid rgba(0,0,0,0.023)',
                padding:'15px',
                borderRadius: '15px'
            }}
            >
            <Text style={{
                textDecoration: 'underline',
                color: 'teal',
                cursor: 'pointer',
                textWrap: 'nowrap'}}
                onClick={()=>props.closeSection()}
                >
                cancel
            </Text>
            <FormControl>
                <Flex flexDirection={viewPort?'row':"column"} alignItems={'center'}>
                <FormLabel style={{textWrap:'nowrap'}}>Enter Email :</FormLabel>
                <VStack width={'100%'}>
                    <Input
                    type='email'
                    value={email}
                    placeholder='college email'
                    style={{textAlign:'center'}}
                    onChange={(e)=>setEmail(e.target.value)}
                    />
                    {
                        authenticity===0?'':(
                            <Text>{authenticityMessages[authenticity]}</Text>
                        )
                    }
                </VStack>
                </Flex>
                <br />
                <Center>
                <Button isDisabled={authenticity!==1} onClick={handleSubmit} colorScheme="blue">Update Email</Button>
                </Center>
            </FormControl>
            </motion.div>
        )
    }

    return (
        <MainContainer heading='Verify your account !' >
        <br />
        <Flex style={{alignItems:"center", justifyContent:'center', 
            flexDirection:"column", gap: '25px'}} >
            <Lottie animationData={locked} />
            <Text>You need to verify that you own your college email
                before you can use this service...</Text>
            <Button colorScheme="blue" onClick={requestEmail}>{otp?'Resend':"Send"} OTP</Button>

            {
                otp?<>
                <OTPForm/>
                </>
                :
                <Text style={{
                    textDecoration: 'underline',
                    color: 'teal',
                    cursor: 'pointer',
                    textWrap: 'nowrap'}}
                    onClick={()=>{vibFn(1); setOtp(true)}}
                    >
                    I have an OTP
                </Text>
            }
            {
                changeEmail?<>
                <ChangeEmail closeSection={()=>{vibFn(1); setChangeEmail(false);}} />
                </>
                :
                <Text style={{
                    textDecoration: 'underline',
                    color: 'teal',
                    cursor: 'pointer',
                    textWrap: 'nowrap'}}
                    onClick={()=>{vibFn(1); setChangeEmail(true)}}
                    >
                    Incorrect email ? Update email
                </Text>
            }

        </Flex>
        <br />
        </MainContainer>
    )
}