import { useEffect, useState, useContext } from "react";
import { FormControl, Spinner, FormLabel, Input, Button, useToast, Center, } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

import MainContainer from "../UI/MainContainer";
import Invalid from "../UI/invalid";
import VPContext from "../others/VPContext";
import postData from "../../backend/postData";
import vibFn from "../others/vibration";

export default function ChangePW() {
    const toast = useToast()
    const viewPort = useContext(VPContext)
    const navigate = useNavigate()
    const [status, setStatus] = useState(false)

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        width: viewPort?'50%':'100%',
        margin: 'auto'
    }

    useEffect(()=>{
        const verifyFn = async()=>{
            if(window.location.search){
                const queries = window.location.search.split('?')[1].split('&')
                const querieSet = {}
                queries.forEach((querie)=>{
                  querieSet[querie.split('=')[0]] = querie.split('=')[1].replace(/\+/g, ' ')
                })
                if(querieSet.hasOwnProperty('u')&&querieSet.hasOwnProperty('s')){
                    const response = await postData('/api/verifylink', {
                        username: querieSet.u,
                        type: 0,
                        s: querieSet.s
                    })
                    if(response.status == 200) setStatus('real')
                    else setStatus('unreal')
                } else window.location.href='/'
        
            } else window.location.href='/'
        }
        verifyFn()
    })

    function PWForm() {
        const [formData, setFormData] = useState()

        function handleChange(e) {
            setFormData({...formData, [e.target.id]: e.target.value})
        }

        async function handleSubmit() {
            vibFn(3)
            if(!(formData&&formData.password&&formData.confPassword))
                toast({
                    title: 'Complete the form.',
                    description: "Kindly enter your new password in both the fields to continue...",
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            else if (formData.password != formData.confPassword)
                toast({
                    title: 'Passwords do not match.',
                    description: "Please enter the same password in both the fields...",
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            else {
                if(window.location.search){
                    const queries = window.location.search.split('?')[1].split('&')
                    const querieSet = {}
                    queries.forEach((querie)=>{
                      querieSet[querie.split('=')[0]] = querie.split('=')[1].replace(/\+/g, ' ')
                    })
                    if(querieSet.hasOwnProperty('u')&&querieSet.hasOwnProperty('s')){
                        const response = await postData('/api/changepw', {
                            password: formData.password,
                            s: querieSet.s,
                            username: querieSet.u
                        })
                        if(response.status==200){
                            toast({
                                title: 'Password Updated.',
                                description: "Your password has successfully been updated...",
                                status: 'success',
                                duration: 4000,
                                isClosable: true,
                            })
                            navigate('/login')
                        }
                        else
                            toast({
                                title: 'Error Encountered.',
                                description: "Error encountered while changing password, please try again later...",
                                status: 'error',
                                duration: 2000,
                                isClosable: true,
                            })
                    }
            
                }
            }
        }

        return (
            <motion.div
                initial={{opacity:0}}
                animate={{opacity:1, transition:{duration:1}}}
            >
            <FormControl style={formStyle}>
                <FormLabel>
                    New Password
                </FormLabel>
                <Input
                    textAlign={'center'}
                    id='password'
                    value={formData&&formData.password}
                    type="password"
                    onChange={handleChange}
                    placeholder="new password"
                />
                <FormLabel>
                    Confirm Password
                </FormLabel>
                <Input
                    textAlign={'center'}
                    id='confPassword'
                    value={formData&&formData.confPassword}
                    type="password"
                    onChange={handleChange}
                    placeholder="new password"
                />
                <br />
                <Button colorScheme="blue" onClick={handleSubmit}>Confirm</Button>
            </FormControl>
            </motion.div>
        )
    }

    return (
        <>
        <MainContainer heading='Change Password'>
            <br />
        {
            !status?
            <>
            <br />
            <Center><Spinner/></Center>
            <br />
            </>
            :
            (status=='real'?'':<Invalid/>)
        }
        {
            status != 'real'?'':<PWForm/>
        }
        </MainContainer>
        </>
    )
}