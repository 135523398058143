import { Flex } from "@chakra-ui/react"

import HomeButton from "./components/homeButton"
import LogoutButton from "./components/logoutButton"
import { ErrandsButton, RequestsButton, NewRequestButton, PlacesButton, UserButton } from './components/actionButtons'

import { UITheme } from "../../others/colors"

export default function Navbar() {
    return (
        <>
        <div style={{
            backgroundColor: UITheme().navBar,
            height: '8.5vh',
            color:'white',
        }}>
            <Flex justifyContent={'space-between'} >
                <Flex style={{boxSizing:"border-box", width: '100%', height:'8.5vh'}} alignItems={'center'} justifyContent="space-evenly">
                    <NewRequestButton/>
                    <PlacesButton/>
                    <ErrandsButton/>
                </Flex>
                <Flex alignItems='center' justifyContent={'center'}>
                    <HomeButton/>
                </Flex>
                <Flex style={{boxSizing:"border-box", width: '100%', height:'8.5vh'}} alignItems={'center'} justifyContent={'space-evenly'} >
                    <RequestsButton/>
                    <UserButton/>
                    <LogoutButton/>
                </Flex>
            </Flex>
        </div>
        </>
    )
}