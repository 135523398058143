import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { Heading, Box, Flex, Center } from "@chakra-ui/react";
import { FaArrowLeft } from 'react-icons/fa'
import { motion } from 'framer-motion'

import VPContext from "../others/VPContext";
import vibFn from "../others/vibration";
import { UITheme } from "../others/colors";
import { containerStyle, heading } from "../others/style";

function MainContainer(props) {
    const viewPort = useContext(VPContext)
    const navigate = useNavigate()

    return (
        <motion.div
            initial={{y:window.innerHeight, scale:0, opacity: 0}}
            animate={{y:0, scale: 1, opacity: [0,0,0.5,1], transition:{duration:0.3, type: "tween"}}}
            exit={{opacity:0, transition: {duration: 0.1}}}
            // exit={{y:window.innerHeight, transition:{duration:0.08}}}
        >
        <Box 
        style={containerStyle(!viewPort)}>
            <Flex>
                {
                !props.heading?'':
                <>
                    <Flex style={{boxSizing: 'border-box', width:'100%',}} alignItems={'center'}>
                        {!props.nav?"":
                        <FaArrowLeft style={{cursor:"pointer", color:'white', backgroundColor:UITheme(!viewPort).navBar, 
                            borderRadius:'50%', border:('4px solid '+UITheme(!viewPort).navBar)}} onClick={()=>{vibFn(1);navigate(-1)}} size='2em'/>
                        }
                    </Flex>
                    <Center>
                    <Heading style={heading()}>
                        {props.heading}
                    </Heading>
                    </Center>
                </>
                }
            <Flex style={{boxSizing: 'border-box', width:'100%',}}>
            </Flex>
            </Flex>
            {props.children}
        </Box>
        <br />
        </motion.div>
    )
}

export default MainContainer