import { Flex, Text, HStack } from "@chakra-ui/react"
import { useNavigate, useLocation } from "react-router"
import { FaArrowLeft } from 'react-icons/fa'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, useAnimation } from "framer-motion"
import { useState, useEffect, useContext } from "react"


import { UITheme } from "../../others/colors"
import vibFn from "../../others/vibration"
import { faBars } from '@fortawesome/free-solid-svg-icons'

import MenuContext from "../../others/MenuContext"

function NavbarMobTop() {
    const navigate = useNavigate()
    const location = useLocation()
    const [drop, setDrop] = useState(false)
    const controls0 = useAnimation()

    const {overlayState,toggleOverlay} = useContext(MenuContext)

    useEffect(()=>{
        controls0.start({
            // backgroundColor:drop?'cyan':'transparent',
            transform: drop?'rotatez(-90deg)':'rotatez(0)',
            transition: {duration:0.2}
        })
    },[drop, controls0])
    useEffect(()=>{
        if(drop){
            setDrop(false);
        }
        if(overlayState) toggleOverlay()
    },[location])
    useEffect(()=>{
        if(overlayState===false && drop) setDrop(false)
    })


    return (
        <>
        <div
            style={{
                backgroundColor: UITheme(true).navBar,
                height: '6vh',
                color:'white',
                alignContent:'center',
        }}>
           <Flex style={{alignItems:'center', justifyContent:'space-between', 
            padding:"1em", height:'100%',}}>
                <HStack alignItems={'center'}>
                <FaArrowLeft 
                style={{visibility:window.location.pathname==='/'?'hidden':'visible'}} 
                onClick={()=>{vibFn(1);navigate(-1)}} size='1.3em'/>
                <Text style={{fontSize:'1.5rem'}}>getKarma</Text>
                {/* <Text style={{fontSize:'1.5rem', textDecoration:'underline', textDecorationColor:'cyan'}}>getKarma</Text> */}
                </HStack>
                <motion.div
                    animate={controls0}
                >
                <FontAwesomeIcon onClick={()=>{vibFn(2);toggleOverlay();setDrop(!drop)}} style={{fontSize: '2em'}} icon={faBars} />
                </motion.div>
           </Flex>
        </div>
        </>
    )
}

export default NavbarMobTop