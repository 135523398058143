import { useState, useEffect, useContext } from 'react'
import { Box, Flex, Select, Skeleton, useToast,
    FormControl, FormLabel, Switch
 } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faSearch } from '@fortawesome/free-solid-svg-icons'

import { Cards3 } from '../elements/cards'

import postData from '../../backend/postData'
import VPContext from '../others/VPContext'
import vibFn from '../others/vibration'


import MainContainer from '../UI/MainContainer'
import NothingFound from '../UI/nothingFound'

const places = require('../others/places')

function AvailableRequests() {

    const viewPort = useContext(VPContext)
    const toast = useToast()
    const [requests, setRequests] = useState()
    const [location, setLocation] = useState(window.sessionStorage.qrplc)
    const [payMode, setPayMode] = useState(false)

    useEffect(()=>{
        if(window.sessionStorage.payMode === 'true') setPayMode(true)
        window.sessionStorage.payMode = ''
    },[])
    
    setTimeout(()=>window.sessionStorage.qrplc = '',700)

    async function fetchRequests() {
        const response = await postData('/api/requests')
        if(response.status === 200) setRequests(response.data.requests)
        else
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while fetching data, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    useEffect(()=>{
        fetchRequests()
    },[])

    function handleLocationChange(e) {
        setLocation(e.target.value)
    }

    function filter(reqs) {
        return !location ? reqs : reqs.filter(req=>req.location === location)
    }

    function payModeFilter(reqs) {
        return payMode ? reqs.filter(req=>req.payMode): reqs
    }

    function PayModeToggle() {

        const handleChange = e=>{
            setTimeout(
                ()=>{vibFn(2);setPayMode(!payMode)},100
            )
        }

        const toggleStyle ={
            borderRadius:'15px', backgroundColor:'#118c4f', 
            margin:'auto', textWrap: 'nowrap', color: 'white', padding: '5px',
            marginLeft: viewPort?'auto':'10px', fontWeight: '600',
            width: viewPort?'60%':''
        }

        return (
            <Box style={toggleStyle}>
                <FormControl>
                    <Flex alignItems={'center'} justifyContent={'center'} gap='10px' flexDirection={viewPort?'row':'column'}>
                        <FormLabel margin={'0'} alignItems={'center'} justifyContent={'center'}>
                            earn
                            <FontAwesomeIcon color='#cda045' icon={faBolt} />
                        </FormLabel>
                        <Switch onChange={handleChange} defaultChecked={payMode} margin='0' colorScheme='yellow' />
                    </Flex>
                </FormControl>
            </Box>
        )
    }

    return (
        <MainContainer heading='Available Requests' >
            <br /><br />
            <Flex>
                <Flex justifyContent={'flex-end'} alignItems={'center'} padding={'15px'} width={'20%'}>
                    <FontAwesomeIcon icon={faSearch} style={{scale:'1.5'}} color='gray' />
                </Flex>
                <Select onClick={()=>vibFn(1)} border={'1.5px solid rgba(0,0,0,0.14)'} textAlign={'center'} width='60%' margin='auto' onChange={handleLocationChange} value={location} variant={'filled'} placeholder='All Locations' >
                    {places.locations.map((loc,key)=>(
                        <option key={key} value={loc.name}>{loc.name}</option>
                    ))}
                </Select>
                <Flex alignItems={'center'} width={'20%'}><PayModeToggle/></Flex>
            </Flex>
            <br />
            <Box>
                {requests ? (
                    payModeFilter(filter(requests)).length?
                    payModeFilter(filter(requests)).map((request, key)=>(
                        <Skeleton isLoaded key={key}>
                            <Cards3 type='availableRequests' fetchagain={async()=> await fetchRequests()} data={request} />
                        </Skeleton>)
                    )
                    :
                    <NothingFound/>
                    ):
                    <Flex flexDirection={'column'} gap='10px'>
                        <Skeleton height={'100px'} />
                        <Skeleton height={'100px'} />
                        <Skeleton height={'100px'} />
                        <Skeleton height={'100px'} />
                    </Flex>
                    }
            </Box>
        </MainContainer>
    )
}

export default AvailableRequests