import { Flex, Box, Spinner, Text, HStack, Divider, Skeleton, useToast } from "@chakra-ui/react"
import { useState, useContext, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faBolt, faHouseUser, faIndianRupeeSign, faPersonRunning, faCheck, faXmark, faClock, faUsers } from '@fortawesome/free-solid-svg-icons'
import { motion } from "framer-motion"

import { ViewMyRequest, SelectCandidate }  from "../sections/viewMyRequest"
import ViewErrand from "../sections/viewErrand"
import ViewRequest from "../sections/viewRequest"

import VPContext from "../others/VPContext"
import postData from "../../backend/postData"
import vibFn from "../others/vibration"

function formatMsToHoursAndMinutes(ms) {
    ms -= Date.now()
    if(ms<0) return 'Time finished'
    const totalMinutes = Math.floor(ms / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    // const formattedTime = `${hours} hrs ${minutes} min`;
    const formattedTime = `${hours}h ${minutes}min`;
    return formattedTime;
}

const cardStyle = {
    width: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '1px solid rgba(0,0,0,0.14)',
    marginTop: '5px',
    marginBottom: '5px',
    borderRadius: '5px',
    cursor: 'pointer',
    userSelect: 'none'
}

const itemStyle = {
    fontSize: 'large',
    color: 'black',
    fontWeight:'600',
    textWrap:'nowrap',
    boxSizing: 'border-box',
    width: '100%'
}

const appearAnim = {
    init: {opacity:0},
    anim: {opacity:1},
    trans: {duration:0.5}
}

const clusterStyle = {
    // backgroundColor: '#fadde7',
    // // backgroundColor: '#008000',
    // borderRadius: '10px', color:'white',
    // // scale: '1',
    // fontSize: '0.8em',
    // fontWeight: '600',
    color: 'gray'
    // paddingLeft: '10px',
    // paddingRight: '10px',
}

function NaviPath(props){
    const locStyle = {
        // border: '2px solid '+ UITheme().navBar ,
        // backgroundColor: UITheme().navBar,
        borderRadius: '5px',
        paddingRight: '4px',
        paddingLeft: '4px',
        paddingTop: '1px',
        paddingBottom: '1px',
    }
    const locNameStyle = {
        fontSize: 'small',
        color: 'gray',
        textWrap: 'nowrap',
        fontWeight:'500'
    }
    const iconStyle = {
        // backgroundColor: UITheme().navBar,
        // backgroundColor: 'gray',
        backgroundColor: 'rgba(65, 87, 144,0.75)',
        // padding: '4px',
        borderRadius: '50%',
        height: '25px', width: '25px',
        justifyContent:'center', alignItems: 'center'
        // color: 'white'
    }
    return (
        <>
            <HStack>
            {/* <HStack style={locStyle} >
                <Text style={locNameStyle} >{props.loc}</Text>
                <FontAwesomeIcon color='white' icon={faLocationDot} />
            </HStack>
            <Divider border='1px solid gray'/>
            <HStack style={locStyle} >
                <FontAwesomeIcon color='white' icon={faHouseUser} />
                <Text style={locNameStyle} >{props.dest}</Text>
            </HStack> */}
            <Flex style={{boxSizing:"border-box", width: '100%'}} justifyContent={'flex-end'}>
            <HStack style={locStyle} >
                <Text style={locNameStyle} >{props.loc}</Text>
                <Flex style={iconStyle}>
                    <FontAwesomeIcon color='white' icon={faLocationDot} />
                </Flex>
            </HStack>
            </Flex>
            <Divider border='1px solid gray' flexGrow='2' />
            <Flex style={{boxSizing:"border-box", width: '100%'}}>
            <HStack style={locStyle} >
                <Flex style={iconStyle}>
                    <FontAwesomeIcon color='white' icon={faHouseUser} />
                </Flex>
                <Text style={locNameStyle} >{props.dest}</Text>
            </HStack>
            </Flex>
            </HStack>
        </>
    )
}

function CompletingStatus(props){
    const textStyle={
        fontWeight:'500',
        color: 'red',
        textWrap: 'nowrap',
    }
    return (
        <Flex style={{alignItems:'center', justifyContent:'center', gap:'5px'}}>
        {
            props.data.status=='completing'?
            <>
            <Spinner size='sm' color='red'/>
            <Text style={textStyle}>
                Verification Required to Complete
            </Text>
            </>
            :''
        }
        </Flex>
    )
}
function KarmaIndicator(){
    const viewPort = useContext(VPContext)
    const indicatorStyle = {
        paddingLeft:'12px',
        paddingRight:'12px',
        paddingTop:'3px',
        paddingBottom:'5px',
        margin:'2px',
        borderRadius: '20px',
        backgroundColor: '#fa2a5599',
        color: 'white',
        fontWeight: '600',
        // gap:'5px',
        textWrap: 'nowrap',
    }
    return (
        <Box style={!viewPort?{margin:'auto'}:{}}>
        <Box style={indicatorStyle}>
            earn 1 Karma
        </Box>
        </Box>
    )
}
function PayModeIndicator(props){
    // props
    // payMode is the amount of delivery money (0 for free)
    // pay = true for pay, else for earn

    const viewPort = useContext(VPContext)

    const indicatorStyle = {
        paddingLeft:'12px',
        paddingRight:'12px',
        paddingTop:'3px',
        paddingBottom:'5px',
        margin:'2px',
        borderRadius: '20px',
        backgroundColor: '#118c4f',
        color: 'white',
        fontWeight: '600',
        gap:'5px',
        textWrap: 'nowrap',
    }

    return (
        <>
        {
        props.payMode?
        <Box style={!viewPort?{margin:'auto'}:{}}>
        <HStack style={indicatorStyle}>
            <Text>
            {props.pay?'pay':'earn'}
            </Text>
            <FontAwesomeIcon style={{transform:"scale(2.5)", paddingLeft:"10px", paddingRight:"10px"}} color='#cda045' icon={faBolt}/>
            <Text>
            <FontAwesomeIcon fontSize='small' icon={faIndianRupeeSign}/>
            {' '}
            {props.payMode}
            </Text>
        </HStack></Box>:''
        }
        </>
    )
}

export function Cards1(props) {
    const [modalOpen, setModalOpen] = useState(false)

    const viewPort = useContext(VPContext)

    const [name, setName] = useState()

    useEffect(()=>{
        const fetchName = async()=>{
            const takerName = await postData('/api/getName', {id: props.data.takerid})
            if(takerName.status === 200) setName(takerName.data.name)
                else setName('error...')
        }
        fetchName()
    },[])

    const getModal=()=>{
        if(props.type==='myRequests')
            return <ViewMyRequest request={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>setModalOpen(false)} />
        else if(props.type==='myErrands')
            return <ViewErrand errand={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>setModalOpen(false)} />
    }

    return (
        <>
        <motion.div 
        initial={appearAnim.init}
        animate={appearAnim.anim}
        transition={appearAnim.trans}
        onClick={()=>{vibFn(1);setModalOpen(true)}} style={cardStyle}>
            <Flex justifyContent={'space-between'} flexDirection='column' padding='20px'>
                <Flex flexDirection={viewPort?'row':'column'} justifyContent={'space-between'} width='100%' >
                    <Text style={itemStyle}>{props.data.item}</Text>
                    <PayModeIndicator pay={props.type==='myRequests'?true:false} payMode={props.data.payMode} />
                    {props.type==='myErrands'?(props.data.payMode?'':<KarmaIndicator/>):''}
                    <Flex style={{boxSizing:'border-box', width:'100%', justifyContent:'flex-end'}}>
                    <Flex style={clusterStyle} gap='20px' justifyContent={'center'} alignItems={'center'}>
                        <Flex gap='5px' alignItems={'center'}>
                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                            <Text fontSize='large'>{props.data.cost}</Text>
                        </Flex>
                        <Flex gap='5px' alignItems={'center'}>
                            {
                                props.type==='myErrands'?
                                <>
                                <FontAwesomeIcon icon={faClock} />
                                <Text fontSize='large'>{formatMsToHoursAndMinutes(props.data.time)}</Text>
                                </>
                                :
                                <>
                                <FontAwesomeIcon icon={faPersonRunning} />
                                <Skeleton isLoaded={name} fadeDuration={1}>
                                <Text fontSize='large'>{name}</Text>
                                </Skeleton>
                                </>
                            }
                        </Flex>
                    </Flex>
                    </Flex>
                </Flex>
                <CompletingStatus data={props.data} />
                <NaviPath loc={props.data.location} dest={props.data.hostel} />
                <Flex flexDirection={'column'} justifyContent={'space-evenly'} >
                    {/* <Flex gap='5px' flexDirection={'row'} alignItems={'center'}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <Text>{props.data.location}</Text>
                    </Flex>
                    <Flex gap='5px' flexDirection={'row'} alignItems={'center'}>
                        <FontAwesomeIcon icon={faHouseUser} />
                        <Text>{props.data.hostel}</Text>
                    </Flex> */}
                </Flex>
            </Flex>
        </motion.div>
        {
            modalOpen?
                getModal()
            :''
        }
        </>
    )
}

export function Cards2(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [modal2Open, setModal2Open] = useState(false)

    const viewPort = useContext(VPContext)

    const expandButtonStyle = {
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '20px',
        margin: '10px',
        backgroundColor: '#EEDC5B',
        width: '4em'
    }

    const getModal=()=>{
        if(props.type==='myRequests')
            return <ViewMyRequest request={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>{setModalOpen(false)}} />
    }
    const getModal2=()=>{
        if(props.type==='myRequests')
            return <SelectCandidate request={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>{setModal2Open(false)}} />
    }

    return (
        <>
        <motion.div 
        initial={appearAnim.init}
        animate={appearAnim.anim}
        transition={appearAnim.trans}>
        <Flex style={cardStyle} flexDirection={viewPort?'row':'column'}>
            <Flex onClick={()=>{vibFn(1);setModalOpen(true)}} justifyContent={'space-between'} flexDirection={viewPort?'row':'column'} padding='20px' width='100%' >
                <Flex gap='20px' flexDirection={'column'} width={'100%'}>
                    <Flex flexDirection={viewPort?'row':'column'} justifyContent={'space-between'}>
                        <Text style={itemStyle}>{props.data.item}</Text>
                        <PayModeIndicator pay={props.type==='myRequests'?true:false} payMode={props.data.payMode} />
                        {/* {props.type==='availableRequests'?(props.data.payMode?'':<KarmaIndicator/>):''} */}
                        <Flex style={{boxSizing:'border-box', width:'100%', justifyContent:'flex-end'}} >
                        <Flex style={clusterStyle} gap='20px' justifyContent={'center'} alignItems={'center'}>
                            <Flex gap='5px' alignItems={'center'} >
                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                            <Text fontSize='large'>{props.data.cost}</Text>
                            </Flex>
                            <Flex gap='5px' alignItems={'center'} >
                            <FontAwesomeIcon icon={faClock} />
                            <Text fontSize='large'>{formatMsToHoursAndMinutes(props.data.time)}</Text>
                            </Flex>
                        </Flex>
                        </Flex>
                    </Flex>
                    <NaviPath loc={props.data.location} dest={props.data.hostel} />
                </Flex>
                <Flex flexDirection={'column'} justifyContent={'space-evenly'} >
                    {/* <Flex gap='5px' flexDirection={'row'} alignItems={'center'}>
                        <FontAwesomeIcon icon={faHouseUser} />
                        <Text>{props.data.hostel}</Text>
                        </Flex> */}
                </Flex>
            </Flex>
            <Flex alignItems={'center'} flexDirection={viewPort?'column':'row'} padding='10px'>
                <Flex justifyContent={'flex-end'} flexDirection={'column'} style={{height:'100%', boxSizing:'border-box'}}>
                    <Text style={{textWrap:'nowrap'}}>Approve people for delivery...</Text>
                </Flex>
                <FontAwesomeIcon onClick={()=>{vibFn(1);setModal2Open(true)}} style={expandButtonStyle} icon={faUsers} />
                <Box style={{height:'100%', boxSizing:'border-box'}}>
                </Box>
            </Flex>
        </Flex>
        </motion.div>
        {
            modalOpen?
                getModal()
            :''
        }
        {
            modal2Open?
                getModal2()
            :''
        }
        </>
    )
}

export function Cards3(props) {
    const [modalOpen, setModalOpen] = useState(false)

    const viewPort = useContext(VPContext)

    const getModal=()=>{
        if(props.type==='myRequests')
            return <ViewMyRequest request={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>setModalOpen(false)} />
        else if(props.type==='availableRequests')
            return <ViewRequest req={props.data} fetchagain={async ()=>{await props.fetchagain()}} onclose={()=>{setModalOpen(false)}}/>
    }

    return (
        <motion.div 
        initial={appearAnim.init}
        animate={appearAnim.anim}
        transition={appearAnim.trans} onClick={()=>{vibFn(1);setModalOpen(true)}}>
        <Box style={cardStyle}>
            <Flex flexDirection={'column'} padding='20px'>
                <Flex gap='5px' justifyContent={'space-between'} flexDirection={viewPort?'row':'column'}>
                    <Text style={itemStyle}>{props.data.item}</Text>
                    <PayModeIndicator pay={props.type==='myRequests'?true:false} payMode={props.data.payMode} />
                    {props.type==='availableRequests'?(props.data.payMode?'':<KarmaIndicator/>):''}
                    <Flex style={{boxSizing:'border-box', width:'100%', justifyContent:'flex-end'}} >
                    <Flex style={clusterStyle} gap='20px' justifyContent={'center'} alignItems={'center'}>
                        <Flex alignItems={'center'} gap='5px' >
                            <FontAwesomeIcon icon={faIndianRupeeSign} />
                            <Text>{props.data.cost}</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap='5px' >
                            <FontAwesomeIcon icon={faClock} />
                            <Text>{formatMsToHoursAndMinutes(props.data.time)}</Text>
                        </Flex>
                    </Flex>
                    </Flex>
                </Flex>
                <NaviPath loc={props.data.location} dest={props.data.hostel} />
            </Flex>
        </Box>
        {
            modalOpen?
                getModal()
            :''
        }
        </motion.div>
    )
}

export function Cards4(props) {
    const [modalOpen, setModalOpen] = useState(false)

    const viewPort = useContext(VPContext)
    const toast = useToast()

    const rejectButtonStyle = {
        borderRadius: '5px',
        cursor: 'pointer',
        margin: '10x',
        padding: '10px',
        backgroundColor: '#c97a78'
    }

    const getModal=()=>{
        if(props.type==='myErrands')
            return <ViewErrand errand={props.data} fetchagain={async()=>await props.fetchagain()} onclose={()=>setModalOpen(false)} />
    }

    async function withdrawCandidate() {
        const response = await postData('/api/withdrawCandidate', {
            requestid: props.data._id,
        })
        if(response.status === 200) //console.log('Request rejected')
        toast({
            title: 'Successfully opted out !',
            description: "You have withdrawn from this delivery request...",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
        else 
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while opting out, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
        await props.fetchagain()
    }

    return (
        <>
        <motion.div 
        initial={appearAnim.init}
        animate={appearAnim.anim}
        transition={appearAnim.trans}>
        <Flex style={cardStyle} flexDirection={viewPort?'row':'column'} gap='15px' padding='20px'>
            <Flex flexDirection={'column'} onClick={()=>{vibFn(1);setModalOpen(true)}} width='100%'>
            <Flex justifyContent={'space-between'} flexDirection={viewPort?'row':'column'} >
                <Text style={itemStyle}>{props.data.item}</Text>
                <PayModeIndicator pay={props.type==='myRequests'?true:false} payMode={props.data.payMode} />
                {props.type==='myErrands'?(props.data.payMode?'':<KarmaIndicator/>):''}
                <Flex style={{boxSizing:'border-box', width:'100%', justifyContent:'flex-end'}}>
                <Flex style={clusterStyle} gap='20px' justifyContent={'center'} alignItems={'center'}>
                    <Flex gap='5px' alignItems={'center'}>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />
                        <Text fontSize='large'>{props.data.cost}</Text>
                    </Flex>
                    <Flex gap='5px' alignItems={'center'}>
                        <FontAwesomeIcon icon={faClock} />
                        <Text fontSize='large'>{formatMsToHoursAndMinutes(props.data.time)}</Text>
                    </Flex>
                </Flex>
                </Flex>
            </Flex>
            <NaviPath loc={props.data.location} dest={props.data.hostel} />
            </Flex>
            <Flex alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
                <Flex flexDirection={'row'} alignItems={'center'} onClick={()=>{vibFn(3);withdrawCandidate()}} margin='auto' justifyContent={'center'} gap='5px' style={rejectButtonStyle}>
                    <FontAwesomeIcon color='white' icon={faXmark} />
                    <Text style={{textWrap:'nowrap', color:'white', fontWeight:'500'}}>Cancel</Text>
                </Flex>
            </Flex>
        </Flex>
        </motion.div>
        {
            modalOpen?
                getModal()
            :''
        }
        </>
    )
}