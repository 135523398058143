import { useState } from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import logout from "../../../../backend/logout";
import vibFn from "../../../others/vibration";

function LogoutButton () {
    const [buttonStatus, setButtonStatus] = useState(true)
    return (
        <>
            {
                buttonStatus?
                    <Flex flexDirection={'column'} alignItems={'center'} cursor={'pointer'} justifyContent='center' 
                    onClick={()=>{vibFn(4);setButtonStatus(false);logout()}}>
                    <FontAwesomeIcon style={{color: 'red', fontSize: '1.7em'}} icon={faRightFromBracket} />
                    <Text color='red' fontWeight={'600'} >Logout</Text>
                    </Flex>
                    :
                    <Spinner color="red" />
            }
        </>
    )
}

export default LogoutButton