import { FormControl, FormLabel, Input, Button, Select, Spinner, useNumberInput, HStack, VStack, Flex, Skeleton, 
    useToast, Text, Switch, SliderMark, Slider, SliderFilledTrack, SliderTrack, SliderThumb } from '@chakra-ui/react'
import { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faIndianRupeeSign, faPaperPlane, faInfoCircle } from '@fortawesome/free-solid-svg-icons'


import postData from '../../backend/postData'

import profanity from '../others/profanity'
import MainContainer from '../UI/MainContainer'
import VPContext from '../others/VPContext'
import vibFn from '../others/vibration'

const places = require('../others/places')

function NewRequest() {

    const toast = useToast()

    const [payMode, setPayMode] = useState(true)
    const [payCost, setPayCost] = useState('20')
    const [buttonStatus, setButtonStatus] = useState(true)
    const [badText, setBadText] = useState(false)

    let [formData, setFormData] = useState({
        item: '',
        cost: '',
        location: '',
        time: '4',
        hostel: '',
        room : '',
        payMode: payCost
    })
    const [userData, setUserData] = useState()

    const viewPort = useContext(VPContext)

    useEffect(()=>{
        const fetchUserData = async ()=>{
            const response = await postData('/api/userdata')
            if(response.status===200) {
                setUserData(response.data)
            }
            else {
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while fetching status, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            }
        }
        fetchUserData()
    },[toast])

    const {getInputProps, getIncrementButtonProps, getDecrementButtonProps} = useNumberInput({
        step: 1,
        defaultValue: 4,
        min: 1,
        max: 24,
        precision: 0,
    })
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    function handleChange(e) {
        if(e.target.id === 'item' || e.target.id === 'room') {
            let vg = true
            for (let x in profanity) {
                if(String(e.target.value).toLowerCase().includes(profanity[x])) {
                    setBadText(true); 
                    vg = false
                }
            }
            if(vg && badText) setBadText(false)
        }
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    function handlePayModeSwitch() {
        vibFn(2)
        setTimeout(()=>{
            setPayMode(!payMode)
            setFormData({...formData, payMode: !payMode?payCost:false})
        },50)
    }
    
    function handleNumberChangeI(e) {
        vibFn(1)
        setFormData({...formData, time: Number(formData.time)+1})
    }
    function handleNumberChangeD(e) {
        vibFn(1)
        setFormData({...formData, time: Number(formData.time)-1})
    }

    async function handleSubmit() {
        vibFn(3)
        let filled = true
        for(let key in formData) if(!formData[key]&& key!=='payMode') filled = false

        if(!filled)
        toast({
            title: 'Please fill all the fields.',
            description: "You need to fill all the details to submit a delivery request...",
            status: 'warning',
            duration: 3000,
            isClosable: true,
        })
        else {
            setButtonStatus(false)
            const response = await postData('/api/newRequest', formData)
            if(response) {

                setFormData({
                    item: '',
                    cost: '',
                    location: '',
                    time: '4',
                    hostel: '',
                    room : '',
                    payMode: false
                })
                // just close that section, whatever
                window.location.href = '/requests'
            }
            else {
            vibFn(4)
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while submitting request, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })}
        }
    }
    const labelStyles = {
        mt: '2',
        ml: '-2.5',
        fontSize: 'sm',
    }

    return(
        <MainContainer heading='Request An Item' >
            <br /><br />
            <FormControl display={'flex'} gap='25px' flexDirection={'column'}>
                <Flex justifyContent='space-evenly' gap='10px' flexDirection={viewPort?'row':'column'} >
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel>What item do you want to request ?</FormLabel>
                    <Input
                    type = 'text'
                    value = { formData.item }
                    placeholder = 'Item Name'
                    id = 'item'
                    textAlign={'center'}
                    onChange = {handleChange}
                    />
                </VStack>
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel textAlign={'center'}>From where do you want the item ?<br />
                    <span style={{
                        fontSize:"small", color: 'gray', fontWeight:'300'
                    }}>(Delivery fulfillment depends on shop operating hours and availability)</span>
                    </FormLabel>
                    <Select onClick={()=>vibFn(1)} textAlign={'center'} id='location' onChange = {handleChange} variant={'filled'} placeholder='Select Item Location'>
                        {places.locations.map((location,key)=>(
                                <option key={key} value={location.name}>{location.name}</option>
                            ))}
                    </Select>
                </VStack>
                </Flex>
                <Flex justifyContent='space-evenly' gap='10px' flexDirection={viewPort?'row':'column'}>
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel>How much does the item cost ?</FormLabel>
                    <Input
                    type = 'number'
                    value = { formData.cost }
                    placeholder = 'Item Cost'
                    id = 'cost'
                    textAlign={'center'}
                    onChange = {handleChange}
                    />
                </VStack>
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel>Set time limit for delivery (hours)</FormLabel>
                    <HStack>
                        <Button isDisabled={formData.time===1} {...dec} colorScheme='red' variant='outline' onClick={handleNumberChangeD} >-</Button>
                        <Input isReadOnly textAlign={'center'} {...input} value={formData.time} id = 'time'/>
                        <Button isDisabled={formData.time===24} {...inc} colorScheme='blue' variant='outline' onClick={handleNumberChangeI} >+</Button>
                    </HStack>
                </VStack>
                </Flex>
                <Flex justifyContent='space-evenly' gap='10px' flexDirection={viewPort?'row':'column'}>
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel>Name of your hostel</FormLabel>
                    <Select onClick={()=>vibFn(1)} textAlign={'center'} id='hostel' onChange = {handleChange} variant={'filled'} placeholder='Select Hostel'>
                        {places.hostels.map((location,key)=>(
                                <option key={key} value={location.name}>{location.name}</option>
                            ))}
                    </Select>
                </VStack>
                <VStack style={{boxSizing:'border-box', width:'100%', paddingLeft:'20px',paddingRight:'20px'}} >
                    <FormLabel>Hostel Room Number</FormLabel>
                    <Input
                    type = 'string'
                    value = { formData.room }
                    placeholder = 'Room Number'
                    id = 'room'
                    textAlign={'center'}
                    onChange = {handleChange}
                    />
                </VStack>
                </Flex>
                {
                    !badText?'':
                    <Text
                        style={{textAlign:'center', color:'red', fontWeight:"700"}}
                    >
                        PLEASE REMOVE ALL BAD WORDS FROM THE TEXT FIELDS
                    </Text>
                }
                <Text style={{textAlign:'center', color:'gray'}}>
                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                {
                    payMode?
                    <>
                        payMode is enabled by default, disable to spend Karma credits instead.
                    </>:
                    <>
                        Enable payMode to get a paid delivery.
                    </>
                }
                </Text>
                <Flex 
                style={{backgroundColor: '#118c4f', width: viewPort?'60%':'95%', margin:'auto', padding:'15px', 
                borderRadius:'5px', color:'white', alignItems:'center', flexDirection:'column'}} >
                    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-evenly'}>
                        <FormLabel htmlFor='paymode_Switch' style={{textWrap:'nowrap', fontSize:'x-large', padding: '0', fontWeight:'700'}}>
                            PayMode <FontAwesomeIcon color={payMode?'#cda045':'darkgray'} icon={faBolt} />
                        </FormLabel>
                        <Switch id='paymode_Switch' onChange={handlePayModeSwitch} defaultChecked={payMode} colorScheme='yellow' size='lg' />
                    </Flex>
                    {!payMode?'':
                    <>
                    <Slider 
                    defaultValue={payCost}
                    min={10}
                    max={100}
                    step={10}
                    width={viewPort?'60%':'80%'}
                    onChange={val=>{setPayCost(val);vibFn(1);setFormData({...formData, payMode: val})}}
                    >
                        {[10,30,50,70,90].map((v,k)=>(
                            <SliderMark textAlign={'center'} value={v} key={k} {...labelStyles}>
                                {/* • */}
                                <Text paddingLeft='5px' >⦿</Text>
                            </SliderMark>
                        ))}
                        {[20,40,60,80,100].map((v,k)=>(
                            <SliderMark textAlign={'center'} value={v} key={k} {...labelStyles}>
                                ₹{v}
                            </SliderMark>
                        ))}
                        <SliderTrack bg='lightgray'>
                            <SliderFilledTrack bg={'#cda045'}/>
                        </SliderTrack>
                        <SliderThumb boxSize={5}>
                            <FontAwesomeIcon icon={faIndianRupeeSign} color='#118c4f' />
                        </SliderThumb>
                    </Slider>
                    <br />
                    </>
                    }
                    <Text style={{color:'lightgray', fontSize:'small', textAlign:'center'}}>Paid deliveries have higher completion rates and faster delivery times</Text>
                </Flex>
            </FormControl>
            <br />
            <Skeleton margin='auto' isLoaded={userData}>
            <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap='5px'>
                <Text style={{color:'gray', fontWeight:"400", fontSize:viewPort?'medium':'small', textAlign:'center'}} >{!userData?'':
                    (payMode?
                        <>
                        You will have to pay <FontAwesomeIcon icon={faIndianRupeeSign} />{payCost} extra for delivery...
                        </>
                        :
                        (userData.credits<1?
                            <>
                            You need at least 1 Karma credit to request an item delivery...<br/>Kindly use PayMode<FontAwesomeIcon icon={faBolt} /> to get paid delivery...
                            </>
                        :
                        '1 karma credit will be spent to request this item...'
                    ))
                    }</Text>
                <Button colorScheme='green' rightIcon={buttonStatus?<FontAwesomeIcon icon={faPaperPlane} />:<Spinner/>} isDisabled={badText?true:(buttonStatus?(userData && ((userData.credits<1)&&!payMode)):true)} onClick={handleSubmit} >Request Item</Button>
                {!formData.cost?'':
                <Text style={{color:'rgba(0,0,0,0.8)'}}>
                    Pay ₹{Number(formData.cost) + Number(payMode?payCost:0)} at the time of delivery...
                </Text>
                }
            </Flex>
            </Skeleton>
        </MainContainer>
    )
}

export default NewRequest
