import { useState } from "react";
import { Flex, Text, Box, HStack, VStack, Slide, useDisclosure, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ad1 from '../../media/shots/ad1.png'
import ad2 from '../../media/shots/ad2.png'
import ip1 from '../../media/shots/ip1.png'
import ip2 from '../../media/shots/ip2.png'

import vibFn from "../others/vibration";

function isPwa() {     
    var displayModes = ["fullscreen", "standalone", "minimal-ui"];     
    return displayModes.some((displayMode) => 
        window.matchMedia('(display-mode: ' + displayMode + ')').matches); }

export default function InstallSuggest() {
    const [mState, setMState] = useState(
        window.sessionStorage.is?(window.sessionStorage.cb==='true'?true:false): !isPwa()
    )

    // let deferredPrompt;

    // window.addEventListener('beforeinstallprompt', (event) => {
    //     console.log('preinstaller moment')
    //     // Prevent the mini-infobar from appearing on mobile
    //     event.preventDefault();
    //     // Stash the event so it can be triggered later.
    //     deferredPrompt = event;
    //     // Update UI notify the user they can install the PWA
    //     const installButton = document.getElementById('install-btn');
    //     // installButton.style.display = 'block';

    //     installButton.addEventListener('click', () => {
    //         console.log('cwicky')
    //         // Show the install prompt
    //         deferredPrompt.prompt();
    //         // Wait for the user to respond to the prompt
    //         deferredPrompt.userChoice.then((choiceResult) => {
    //         if (choiceResult.outcome === 'accepted') {
    //             console.log('User accepted the install prompt');
    //         } else {
    //             console.log('User dismissed the install prompt');
    //         }
    //         deferredPrompt = null;
    //         });
    //     });
    // });
    const { isOpen, onToggle } = useDisclosure()

    if(mState) return (
        <Flex style={{backgroundColor:'gray', color:"white", gap:'20px',
            textAlign:'center',textJustify:'auto' , fontWeight:'300', fontSize:"small", 
            alignItems:'center', justifyContent:'space-between', padding: '2px 20px 2px 20px'}}>


            <Slide direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
                <Box onClick={()=>{vibFn(1);onToggle()}}>
                    <FontAwesomeIcon 
                    style={{padding:'3px',aspectRatio:'1/1', 
                        borderRadius:'50%',cursor:'pointer',
                        backgroundColor:'rgba(0,0,0,0.6)', color:'white',
                        backdropFilter:"blur(20px)",
                        WebkitBackdropFilter:"blur(20px)"}} 
                    size='2x' icon={faXmark} />
                </Box>
                <Box
                p='40px'
                color='white'
                mt='4'
                style={{color:'white', overflowY:'auto',
                    backdropFilter:"blur(20px)",
                    WebkitBackdropFilter:"blur(20px)"
                }}
                bg='rgba(0,0,0,0.6)'
                borderRadius='35px 35px 0px 0px'
                shadow='md'
                height={'70vh'} 
                >
                    <Box>
                    Follow these instructions on Android and iOS to add this website to your homescreen as an app.
                    <br /><br />
                    <Box padding={'15px 0px 9vh 0px'}>
                    <VStack>
                        <VStack>
                            <Text style={{fontWeight:"700",fontSize:'1.2rem', color:'white'}}>For Android</Text>
                            <HStack justifyContent={'space-evenly'}>
                                <Image width='40%' src={ad1} alt='ad1'/>
                                <Image width='40%' src={ad2} alt='ad2'/>
                            </HStack>
                        </VStack>
                        <VStack>
                            <Text style={{fontWeight:"700",fontSize:'1.2rem', color:'white'}}>For iOS</Text>
                            <HStack justifyContent={'space-evenly'}>
                                <Image width='40%' src={ip1} alt='ip1'/>
                                <Image width='40%' src={ip2} alt='ip2'/>
                            </HStack>
                        </VStack>
                    </VStack>
                    </Box>
                    </Box>
                </Box>
            </Slide>


            <HStack gap='10px'>
                <Text>
                    Add the website to your home screen for a seamless experience
                </Text>
                {/* <Button id='install-btn' >pls</Button> */}
                <Box onClick={()=>{vibFn(1);onToggle()}}>
                    <FontAwesomeIcon style={{cursor:'pointer'}} size='2x' icon={faInfoCircle} />
                </Box>
            </HStack>
        <Box onClick={()=>{vibFn(1);setMState(false); window.sessionStorage.is='false'}}>
            <FontAwesomeIcon style={{cursor:'pointer'}} size='2x' icon={faXmark} />
        </Box>
        </Flex>
    ) 
    else return
}