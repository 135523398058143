import { Flex, Text, Button, Box } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useContext } from "react"
import Lottie from "lottie-react"
import { motion } from "framer-motion"
import vibFn from "../others/vibration"

import error from '../../media/animations/error.json'
import VPContext from "../others/VPContext"

export default function Invalid() {
    const viewPort = useContext(VPContext)
    const boxStyle = {
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px',
        flexDirection: 'column',
    }
    const textStyle = {
        color: '#BB3F3F',
        fontWeight: '500'
    }
    return (
        <motion.div
            initial={{opacity:0}}
            animate={{opacity:1, transition:{duration:1}}}
        >
        <Flex style={boxStyle}>
            <Flex style={{flexDirection:viewPort?'row':'column', alignItems: 'center', justifyContent:'space-evenly'}}>
            <Box style={{width: viewPort?'20vw':'40vw'}}>
                <Lottie height={'100px'} animationData={error} />
            </Box>
            <Text style={textStyle}>The Link you just followed is no longer valid</Text>
            </Flex>
            <br />
            <Link to='/'>
            <Button onClick={()=>vibFn(1)} colorScheme="blackAlpha">Go To Home</Button>
            </Link>
            <br />
        </Flex>
        </motion.div>
    )
}