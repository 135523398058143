import { useState, useEffect, useContext } from "react";
import {FormControl, FormLabel, Input, InputGroup, Checkbox, Box, InputLeftAddon, Button, Spinner, Flex, Image, useToast, Text} from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import register from "../../backend/register";
import VPContext from "../others/VPContext";
import nitj from '../../media/nitj.png'
import vibFn from "../others/vibration";
import postData from '../../backend/postData'

import MainContainer from "../UI/MainContainer";

let availability = false

function CheckAvailability(props) {
    let [availStatus, setAvailStatus] = useState(false)

    const setAvailStatus0 = (stat)=>{
        if(availStatus !== stat){
            setAvailStatus(stat)
            availability = stat
        }
    }

    useEffect(()=>{
        if(!props.username) return
        fetch((process.env.REACT_APP_BACKEND_URL+'/api/usernameCheck?username='+props.username), {
              method: "GET",
              headers: { 'Content-Type': 'application/json',}
            }).then(response=>response.json()).then(data=>{
                if(data.exists) setAvailStatus0(false)
                else setAvailStatus0(true)
            })
    })

    return(
        <>
            {availStatus?(
                // <p>This username is available.</p>
                ''
            ):(
                <p>This user already has an account...</p>
            )}
        </>
    )
}

function RegisterForm() {
    const toast = useToast()
    const [authenticity, setAuthenticity] = useState(0)
    const [tnc, setTnc] = useState(false)

    let [formData, setFormData] = useState({
        // name: '',
        username: '',
        password: '',
        email: '',
        phone: ''
    })
    const [buttonStatus, setButtonStatus] = useState(true)
    const viewPort = useContext(VPContext)


    useEffect(()=>{
        function cleanMail() {
            function hasCapitalLetters(str) {
                return /[A-Z]/.test(str);
            }
            if(formData.email.includes(' ') || hasCapitalLetters(formData.email))
            setFormData({...formData, email: (String(formData.email).toLowerCase()).replace(/\s/g, '')})
        }
        cleanMail()
        const Assfc = async ()=>{
            const response = await postData('/api/userauthenticity', {
                roll: formData.username,
                mail: formData.email
            })
            if(response.status === 200) {
                if(response.data.status==='verified') {
                    setAuthenticity(1)
                }
                else if(response.data.status==='INV') {
                    setAuthenticity(2)
                }
                else if(response.data.status==='NA') {
                    setAuthenticity(3)
                }
            }
            else if (response.status===401) setAuthenticity(0)
            else {
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while checking credentials, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })}
        }
        if(!formData.email) setAuthenticity(0)
        if(formData.username && formData.email) Assfc()
    },[formData, toast])
    
    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        width: viewPort?'50%':'100%',
        margin: 'auto',
    }
    const subformStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        width: 'calc(100% + 30px)',
        border: '5px solid #46769b',
        backgroundColor: '#46769b',
        borderRadius: '25px',
        color: 'white'
    }
    const subsubformStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    }

    function handleChange(e) {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    async function submitForm(){
        vibFn(3)
        setButtonStatus(false)
        if(formData.password && formData.phone ) {
            if (availability) {
                const response = await register(formData)
                if(response) window.location.href='/'
                else {
                    setButtonStatus(true)
                    toast({
                        title: 'Error Encountered.',
                        description: "Error encountered while creating account, please try again later...",
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    })
                    setFormData({username:'', password:'', phone:'', email:''}) // is this even needed ?
                }
            }
            else {
                setButtonStatus(true)
                toast({
                title: 'Roll Number Taken.',
                description: "That roll number already has an account, Kindly reset password...",
                status: 'warning',
                duration: 3000,
                isClosable: true,
                })
            }
        }
        else {
            setButtonStatus(true)
            toast({
                title: 'Kindly fill all the details.',
                description: "Fill the entire form to create an account...",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
        }
    }

    const authenticityMessages = [
        'Fill Email',
        'College Email matched.',
        'College Email did not match.',
        'Kindly enter correct Roll number.'
    ]

    return (
        <>
        <FormControl style={formStyle} mt={4}>
            <Flex style={subformStyle}>
                <Image src={nitj} width={viewPort?'10vw':'20vw'} alt='nitj' />
                <Flex style={subsubformStyle}>
                    <FormLabel>Roll Number</FormLabel>
                    <Input
                    style={{borderColor:'white', backgroundColor:"white", color:'black'}}
                    textAlign={'center'}
                    placeholder='roll number'
                    id='username'
                    type='number'
                    value={formData.username}
                    onChange={handleChange}
                    />
                    {formData.username?(
                        <CheckAvailability username={formData.username} />
                    ):('')}
                    <FormLabel>College Email</FormLabel>
                    <Input
                    style={{borderColor:'white', backgroundColor:"white", color:'black'}}
                    textAlign={'center'}
                    placeholder='email'
                    id='email'
                    type='email'
                    value={formData.email}
                    onChange={handleChange}
                    />
                    {
                        authenticity===0?'':(
                            <Text>{authenticityMessages[authenticity]}</Text>
                        )
                    }
                </Flex>
            </Flex>
            {/* <FormLabel>Name</FormLabel>
            <Input
            textAlign={'center'}
            placeholder='name'
            id='name'
            value={formData.name}
            onChange={handleChange}
            /> */}
            <FormLabel>Phone</FormLabel>
            <InputGroup>
                <InputLeftAddon>+91</InputLeftAddon>
                <Input
                textAlign={'center'}
                placeholder='phone'
                id='phone'
                type='number'
                value={formData.phone}
                onChange={handleChange}
                />
            </InputGroup>
            <FormLabel>Password</FormLabel>
            <Input
            textAlign={'center'}
            placeholder='password'
            id='password'
            type='password'
            value={formData.password}
            onChange={handleChange}
            />
            <Checkbox isChecked={tnc} onChange={()=>setTnc(!tnc)} >
                I have read and I agree to{' '}
                <Link to={'/tnc'} style={{textDecoration:"underline", color:'teal'}}>
                TnC
                </Link>.
            </Checkbox>
        <Button isDisabled={tnc?(!buttonStatus||!(authenticity===1)||!(formData.username)):true} margin={'15px'} rightIcon={!buttonStatus&&<Spinner/>} colorScheme='blue' onClick={submitForm}>REGISTER</Button>
        </FormControl>
        </>
    )
}

function RegisterPage() {
    const viewPort = useContext(VPContext)
    return (
        <MainContainer heading={viewPort?'Become a part of the getKarma family':'Join getKarma'}>
            <RegisterForm/>
            <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Text color={'gray'}><em>Already have an account ?</em></Text>
            <Link to={'/login'}>
                <Button colorScheme="green" onClick={()=>vibFn(1)}>Login</Button>
            </Link>
            </Flex>
        </MainContainer>
    )
}

export default RegisterPage