import { Box, Text, VStack, Flex, Center, HStack } from "@chakra-ui/react";
import { PeelWrapper, PeelTop, PeelBack, PeelBottom} from 'react-peel'
import { useContext } from "react";
import VPContext from "../others/VPContext";
import { faCircleRadiation, faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import favicon from '../../media/favicon.png'
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import StarryBackground from "./StarryBackground";
import vibFn from "../others/vibration";

export default function Promo1() {
    const viewPort = useContext(VPContext)
    const navigate = useNavigate()

    function PayModeIndicator(props){
        // props
        // payMode is the amount of delivery money (0 for free)
        // pay = true for pay, else for earn
    
        const viewPort = useContext(VPContext)
    
        const indicatorStyle = {
            paddingLeft:'12px',
            paddingRight:'12px',
            paddingTop:'3px',
            paddingBottom:'5px',
            margin:'2px',
            borderRadius: '20px',
            backgroundColor: '#118c4f',
            color: 'white',
            fontWeight: '600',
            gap:'5px',
            textWrap: 'nowrap',
        }
    
        return (
            <>
            {
            props.payMode?
            <Box style={!viewPort?{margin:'auto'}:{}}>
            <HStack style={indicatorStyle}>
                <Text>
                {props.pay?'pay':'earn'}
                </Text>
                <FontAwesomeIcon style={{transform:"scale(2.5)", paddingLeft:"10px", paddingRight:"10px"}} color='#cda045' icon={faBolt}/>
                <Text>
                {' '}
                {props.payMode}
                </Text>
            </HStack></Box>:''
            }
            </>
        )
    }

    function Peeler(){
        const badList = (l)=>{
            let retl =[]
            while(l) {
                retl.push(l);  l--
            }
            return retl
        }
        return (
            <>
            <div style={{ padding: "20px" }}>
            <Text textAlign={'center'} color='yellow'>
                <em>peel to reveal</em>
            </Text>
            <PeelWrapper width={viewPort?'20vw':'55vw'} height={viewPort?'20vw':'55vw'} 
            peelPosition={{ x: viewPort?window.innerWidth/6:window.innerHeight/5, y: viewPort? window.innerHeight/6:window.innerHeight/8 }} drag>
                <PeelTop style={{ backgroundColor: "#eed202" }}>
                    <Center height={'100%'}>
                    <FontAwesomeIcon icon={faCircleRadiation} fontSize='10rem' color='black'/>
                    </Center>
                </PeelTop>
                <PeelBack style={{ backgroundColor: "#d4bb02", overflow:"hidden" }}>
                    <Flex flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-evenly'}>
                        {badList(100).map(m=>(
                                <Text color='gray' key={m}>VOID</Text>
                            ))}
                    </Flex>
                </PeelBack>
                <PeelBottom onClick={()=>{navigate('/register')}} style={{ backgroundColor: "lightgray" }}>
                    <VStack padding='5px' justifyContent={'space-evenly'} height={'100%'}>
                        <Flex style={{flexDirection:'column', alignItems:'center'}}>
                            <img width={'50%'} src={favicon} alt="getKarma" />
                            <Text fontFamily={'Outfit'} color='black' fontSize={'1.2rem'}>getKarma</Text>
                        </Flex>
                        <PayModeIndicator payMode={'Cash/Credits'}/>
                        <Text fontFamily={'consolas'} color='black' fontSize={'0.8rem'}>NIT Jalandhar ka delivery app</Text>
                    </VStack>
                </PeelBottom>
            </PeelWrapper>
            <br /><br /><br />
                <Link to='/register' onClick={()=>{vibFn(3)}}>
                    <motion.div
                        whileHover={{scale:'1.1'}}
                    >
                        <Box
                        style={{backgroundColor:'lightgray', color:'red', padding:'5px', textAlign:"center"}}
                        fontFamily={'Consolas'} fontWeight={'700'}
                        >SHOW ME HOW !!!</Box>
                    </motion.div>
                    <Text textAlign={'center'}>
                        click here to explore getKarma
                    </Text>
                </Link>
            </div>
            </>
        )
    }

    return (
        <>
        <StarryBackground/>
        <Box style={{
                color: 'white',
                height:'100svh', width:'100%',
                position:'fixed'
            }}>
            <Center height={'100%'}>
            <br />
            <VStack>
                <Text fontFamily={'consolas'}>
                    something fresh is coming...
                </Text>
                <br />
                <Text fontFamily={'consolas'}>
                    The <span style={{backgroundColor:'lightgreen', color:'red'}}>decentralized</span> delivery app is here
                </Text>
                <br />
                <Text fontFamily={'consolas'}>
                    For NITJ peeps, by NITJ peeps
                </Text>
                <br />
                <Peeler/>
            </VStack>
            </Center>
        </Box>
        </>
    )
}