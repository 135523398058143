import { Box } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { motion, useAnimation } from "framer-motion"

import favicon from '../../../../media/favicon.png'
import { UITheme } from "../../../others/colors"

import VPContext from '../../../others/VPContext'
import { useContext } from "react"
import vibFn from '../../../others/vibration'

function HomeButton(props) {

    const viewPort = useContext(VPContext)
    const controls0 = useAnimation()
    function rotatey() {
        controls0.start({
            transform: 'rotatez(360deg)',
            transition: {duration:0.5, type: "spring", stiffness: 60}
        }).then(()=>{
            controls0.start({
                transform: 'rotatez(0deg)',
                transition: {duration: 0}
            })
        })
    }

    let buttonSize = viewPort?'12vh':'10vh'

    const homeButtonStyle = {
        border: ('5px solid '+UITheme(!viewPort).navBar),
        backgroundColor: UITheme(!viewPort).navBar,
        borderRadius: '100%',
        height: buttonSize,
        width: buttonSize,
        userSelect: 'none',
        cursor: 'pointer',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        transform: props.mobile ? 'translateY(-2vh)':'',
    }

    return (
        <>
            <Link to={'/'}>
                <Box onClick={()=>{vibFn(2); rotatey()}} style={homeButtonStyle}>
                <motion.div
                    animate={controls0}
                    // initial={{opacity:0}}
                    // animate={{opacity:1, transition:{duration:1}}}
                    // whileHover = {{scale:1.1}}
                    style={{height:'100%', width:'100%'}}
                >
                    <motion.div style={{height:'100%', display:"flex",
                        alignItems:'center', justifyContent:"center"}}>
                    <img style={{height:viewPort?'8vh':'6.8vh',margin:'auto'}} src={favicon} alt="Home.png" />
                    </motion.div>
                </motion.div>
                </Box>
            </Link>
        </>
    )
}

export default HomeButton