import NavbarMob from "./navbarMob"
import { Box } from "@chakra-ui/react"
import { useContext } from "react"

import VPContext from "../../others/VPContext"

function BottomBar() {

    const viewPort = useContext(VPContext)

    return (
        <Box zIndex={'100'}>
            {!viewPort?(
                <NavbarMob/>
            ):''}
        </Box>
    )
}

export default BottomBar