import { Box, Text, VStack, Flex, Heading, Spacer, HStack, Center } from "@chakra-ui/react"
import { useContext } from "react"
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa"

import MainContainer from "../UI/MainContainer"
import VPContext from "../others/VPContext"

import jagi from '../../media/jagi.jpg'
import { Link } from "react-router-dom"

export default function Info() {
    const viewPort = useContext(VPContext)

    function Profile() {

        function LinkCasing(props) {
            return (
                <Link to={props.link}>
                    <HStack>
                        <Box>{props.children}</Box>
                        <Text fontSize={'1rem'}>{props.name}</Text>
                    </HStack>
                </Link>
            )
        }

        return (
            <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                <Heading>&lt;Developer/&gt;</Heading>
                <Box height={viewPort?'8vw':'30vw'}/>
                <VStack shadow='lg' style={{
                    backgroundColor:'#e4ebeb',
                    borderRadius:"15px",
                    width:viewPort?'60vw':'95vw'
                }}>
                    {/* <Box border='2px solid red'
                        width={viewPort?'60vw':"95vw"}
                    >
                        <VStack height={'100%'} border='1px solid yellow' zIndex={'3'}>
                            <Box style={{width:'100%', border:"1px solid blue", boxSizing:'border-box'}}>hello</Box>
                            <Box style={{width:'100%', border:"1px solid blue", boxSizing:'border-box'}}>hello</Box>
                        </VStack>
                        <Center border='1px solid purple' width={'100%'} zIndex={'4'}>
                        </Center>
                        </Box> */}
                    <img src={jagi} alt="jagi" 
                    style={{ borderRadius: '50%', 
                        width:viewPort?'15vw':'45vw',
                        margin:viewPort?'-5vw':'-20vw',
                        border:"#e4ebeb 20px solid"
                    }} />
                    <Box>
                        <Box style={{height:viewPort?'5vw':'20vw'}}/>
                        <VStack style={{fontSize:'1.5rem', padding:('5px 5px 5px 5px')}}>
                            <Text>Jagjit Singh</Text>
                        <Center>
                            <Text fontSize={'1rem'} color='gray'>NIT Jalandhar, ECE 2nd Year</Text>
                        </Center>
                            <VStack shadow='md' style={{width:'100%',borderRadius:'15px', backgroundColor:'rgba(256,256,256,0.3)', padding:"10px"}}>
                                <LinkCasing name={'GitHub'} link='https://www.github.com/Jagjit0306'>
                                    <FaGithub/>
                                </LinkCasing>
                                <Spacer border='0.5px solid darkgray'/>
                                <LinkCasing name={'Instagram'} link='https://www.instagram.com/Jagjit0306'>
                                    <FaInstagram/>
                                </LinkCasing>
                                <Spacer border='0.5px solid darkgray'/>
                                <LinkCasing name={'LinkedIn'} link='https://www.linkedin.com/in/jagjit-singh-b2359b2b1/'>
                                    <FaLinkedin/>
                                </LinkCasing>
                            </VStack>
                        </VStack>
                        <br />
                    </Box>
                </VStack>
            </Flex>
        )
    }

    function Boring() {
        return (
            <Center>
            <Box width={viewPort?'60vw':'95vw'} fontSize={'0.9rem'} textAlign={'center'}>
                getKarma started as a personal project, and evolved into a business model. This project aims to bring people closer together by fostering a sense of community through a unique delivery system. By allowing individuals to earn credits or money by helping others with their delivery needs, getKarma promotes a culture of mutual support and collaboration. 

                The app leverages everyday interactions and turns them into opportunities for connection, ensuring that community members can rely on each other for small but significant tasks. What began as a simple idea to facilitate item exchanges has grown into a comprehensive platform that not only meets delivery needs but also strengthens social bonds within campuses and tech villages. 

                As it continues to expand, getKarma is poised to revolutionize the way communities operate, making everyday life more convenient while encouraging people to engage and help one another.
            </Box>
            </Center>
        )
    }

    return (
        <MainContainer heading='About getKarma'>
            <br />
            <Boring/>
            <br />
            <Profile/>
        </MainContainer>
    )
}