import { Box, Text, background } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import requestItem from '../../media/requestItem.png'
import deliverItem from '../../media/deliverItem.png'
import earnMoney from '../../media/earnMoney.png'

import vibFn from "../others/vibration";

function ButtonBG(img) {
    const bgStyle = {
        // height: '5%',
        width: '250px',
        height: '80px',
        backgroundImage: 'url('+img+')',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        border: '1px rgba(0,0,0,0.15) solid',
        borderRadius: '10px',
        padding: '10px',
    }

    return bgStyle
}

export function RequestItemButton() {
    return (
        <Link to='/newRequest'>
            <Box onClick={()=>vibFn(1)} shadow='md' style={ButtonBG(requestItem)}>
                <Text textAlign={'right'} fontWeight={'700'} fontSize={'larger'} >
                    Request an Item
                </Text>
                <Text color='gray' textAlign={'right'} fontSize={'small'} fontWeight={'700'}>pay with Karma/Cash</Text>
            </Box>
        </Link>
    )
}

export function DeliverItemButton() {
    return (
        <Link to='/places'>
            <Box onClick={()=>vibFn(1)} shadow='md' style={ButtonBG(deliverItem)}>
                <Text textAlign={'right'} fontWeight={'700'} fontSize={'larger'} >
                    Deliver Items
                </Text>
                <Text color='gray' textAlign={'right'} fontSize={'small'} fontWeight={'700'}>and earn karma credits</Text>
            </Box>
        </Link>
    )
}

export function EarnButton() {
    const handlePayModeSet = ()=>{
        window.sessionStorage.payMode = 'true'
    }

    return (
        <Link onClick={handlePayModeSet} to={'/places'}>
            <Box onClick={()=>vibFn(1)} shadow='md' style={ButtonBG(earnMoney)}>
                <Text textAlign={'right'} fontWeight={'700'} fontSize={'larger'} >
                    Earn with getKarma
                </Text>
                <Text color='gray' textAlign={'right'} fontSize={'small'} fontWeight={'700'}>by completing deliveries</Text>
            </Box>
        </Link>
    )
}