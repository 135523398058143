
import { Box } from "@chakra-ui/react"

import Navbar from "./navbar"
import NavbarMobTop from "./navbarMobTop"

import { useEffect, useContext } from "react"
import { useLocation } from "react-router"

import checkTokens from "../../../backend/checkTokens"

import VPContext from "../../others/VPContext"

function TopBar() {
    const location = useLocation()
    useEffect(()=>{
        const safePages = ['login', 'register', 'resetpw', 'setpw', 'qr', 'promo1', 'tnc']
        const checkTokensBooster = async ()=>{ // this will log you out if you lose hold of your refresh tokens at any point
            const pageLocation = window.location.pathname.split('?')[0].split('/')[1]
            if(!(safePages.includes(pageLocation))) await checkTokens()
            else return
        }

        checkTokensBooster()

    },[location.pathname])

    const viewPort = useContext(VPContext)

    return (
        <Box zIndex={'100'}>
            {viewPort?(
                <>
                <Navbar/>
                </>
            ):(
                <>
                <NavbarMobTop/>
                </>
            )}
        </Box>
    )
}

export default TopBar