import { Box, Text, useDisclosure, Modal, ModalOverlay, ModalBody, ModalContent, ModalHeader, 
    ModalCloseButton, ModalFooter, Button, Spinner, Flex, Skeleton, useToast } from '@chakra-ui/react'

import { Stepper, Step, StepIndicator, StepSeparator, StepTitle, StepDescription, Divider } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faHouseUser, faUser, faClock, faIndianRupeeSign, 
    faCubes, faPhone, faDoorOpen, faCheck, faIdCard, faTrash } from '@fortawesome/free-solid-svg-icons' 

import postData from '../../backend/postData'
import vibFn from '../others/vibration'
import { useEffect, useState } from 'react'
import { modalStyle } from '../others/style'


function ViewErrand(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [userData, setUserData] = useState()

    async function abandonRequest() {
        vibFn(1)
        const response = await postData('/api/abandonRequest', {id:props.errand._id})
        if(response.status === 200) 
            toast({
                title: 'Successfully abandoned request !',
                description: "You have opted out from fulfilling this request...",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while abandoning request, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
            props.fetchagain()
            onClose2()
        }
        
    async function withdrawCandidate() {
            vibFn(1)
            const response = await postData('/api/withdrawCandidate', {
                requestid: props.errand._id,
            })
            if(response.status === 200) 
                toast({
                    title: 'Successfully opted out !',
                    description: "You have withdrawn from this delivery request...",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            else 
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while abandoning request, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
        props.fetchagain()
        onClose()
    }

    async function completeRequest() {
        const response = await postData('/api/completeRequest', {id:props.errand._id})
        if(response.status === 200) 
            toast({
                title: 'Request Completed !',
                description: "Request process completed, Good Luck !!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while completing request, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        props.fetchagain()
        onClose2()
    }

    useEffect(()=>{
        const fetchUser = async()=>{
            const fetchedUser = await postData('/api/userdataById', {id: props.errand.userid})
            if(fetchedUser.status === 200) setUserData(fetchedUser.data)
            else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while fetching data, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        }
        fetchUser()
    },[props.errand.userid, toast])

    useEffect(onOpen,[onOpen])

    const onClose2 = ()=> {vibFn(1);onClose(); props.onclose()}
    
    const messageStyle={
        fontWeight:'500',
        color:'gray',
        textAlign:'center',
        fontStyle:'italic'
    }

    function formatMsToHoursAndMinutes(ms) {
        ms -= Date.now()
        if(ms<0) return 'Time finished'
        const totalMinutes = Math.floor(ms / 60000);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        const formattedTime = `${hours}h ${minutes}min`;
        return formattedTime;
    }

    function DataSpread(props) {
        return (
            <Flex flexDirection={'row'}>
            <Text style={{boxSizing:'border-box', width:'100%',
            color:'gray', fontWeight: '500', fontSize:'large'}}>{props.left}</Text>
            -
            <Text style={{boxSizing:'border-box', width:'100%', textAlign:'right'}}>{props.right}</Text>
            </Flex>
        )
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose2} isCentered motionPreset='slideInBottom'>
                <ModalOverlay style={{backdropFilter:'blur(5px)'}} />
                <ModalContent style={modalStyle()}>
                <ModalHeader textAlign={'center'}>View Errand</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stepper colorScheme='green' index={(props.errand.status !== 'complete')?'2':'3'}>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faLocationDot} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.errand.location}</StepTitle>
                                <StepDescription>Pickup Location</StepDescription>
                            </Box>
                        </Step>
                        <StepSeparator/>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faHouseUser} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.errand.hostel}</StepTitle>
                                <StepDescription>Delivery Location</StepDescription>
                            </Box>
                        </Step>
                    </Stepper>
                    <br /><Divider/><br />
                    <Box>
                        <DataSpread left={<><FontAwesomeIcon icon={faCubes} /> Item</>} right={props.errand.item} />
                        <Skeleton isLoaded={userData}>
                        <DataSpread left={<><FontAwesomeIcon icon={faUser} /> Requested By</>} right={userData?userData.name:<Spinner/>} />
                        </Skeleton>
                        <Skeleton isLoaded={userData}>
                        <DataSpread left={<><FontAwesomeIcon icon={faIdCard} /> Roll Number</>} right={userData?userData.username:<Spinner/>} />
                        </Skeleton>
                        <DataSpread left={<><FontAwesomeIcon icon={faIndianRupeeSign} /> Cost</>} right={props.errand.cost} />
                        {
                            props.errand.status==='complete'?'':
                            <DataSpread left={<><FontAwesomeIcon icon={faClock} /> Time</>} right={formatMsToHoursAndMinutes(props.errand.time)} />
                        }
                        {
                            !(props.errand.status==='busy'||props.errand.status==='completing')?'':
                            <>
                                <DataSpread left={<><FontAwesomeIcon icon={faDoorOpen} /> Room Number</>} right={props.errand.room} />
                                <Skeleton isLoaded={userData}>
                                <DataSpread left={<><FontAwesomeIcon icon={faPhone} /> Phone</>} right={userData?
                                <a style={{textDecoration:'underline', color:'blue'}} href={'tel:'+userData.phone}>{userData.phone}</a>
                                :<Spinner/>} />
                                </Skeleton>
                            </>
                        }
                    </Box>
                    <br />
                    {
                        props.errand.status==='complete'?'':(
                            props.errand.payMode?
                            <Text style={messageStyle}>Deliver this item to earn ₹{props.errand.payMode}...</Text>
                            :
                            <Text style={messageStyle}>Deliver this item to earn 1 karma credit...</Text>
                        )
                    }

                </ModalBody>

                <ModalFooter>
                    <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width='100%'>
                        <Flex width='100%' justifyContent='space-evenly' >
                            <Button colorScheme='blackAlpha' mr={3} onClick={onClose2}>
                                Close
                            </Button>
                            {props.errand.status !== 'busy'?'':
                                <Button colorScheme='red' onClick={abandonRequest} 
                                rightIcon={<FontAwesomeIcon icon={faTrash} />}>Abandon</Button>
                            }
                            {props.errand.status !== 'awaited'?'':
                                <Button colorScheme='red' onClick={withdrawCandidate} 
                                rightIcon={<FontAwesomeIcon icon={faTrash} />}>Cancel</Button>
                            }
                            {props.errand.status !== 'completing'?'':
                                <Button colorScheme='green' onClick={completeRequest}>
                                    {
                                        props.errand.payMode?
                                        'Payment Recieved'
                                        :
                                        'Delivery Complete'
                                    }
                                <FontAwesomeIcon style={{paddingLeft:'5px'}} icon={faCheck} /></Button>
                            }
                        </Flex>
                        <Text style={messageStyle}>{(
                            props.errand.status !== 'awaited'?(
                                props.errand.status !== 'busy'?
                                (
                                    props.errand.status ==='complete'?
                                    'This item was delivered by you in the past...':
                                    'Did you recieve the payment ?'
                                )
                                :
                                <>
                                Recieve payment of ₹{(Number(props.errand.cost) + Number(props.errand.payMode?props.errand.payMode:0))+' '}
                                from the user upon delivery...
                                <br />
                                <span style={{color:'red', fontWeight:'400'}}>
                                <u>Disclaimer:</u> Recieve payment before handing over the delivery.
                                </span>
                                </>
                            )
                            :'Please wait till the user approves your delivery...'
                        )}</Text>
                    </Flex>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ViewErrand