import MainContainer from "../UI/MainContainer"

export default function Tnc() {
    return (
        <MainContainer nav heading={'Terms and Conditions'}>
            <br />
            <ul style={{padding:'10px'}}>
                <li>
                    Welcome to getKarma, a peer-to-peer delivery app provided by students of NITJ. By using our app, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully before using our service.
                </li>
                <li>
                    You must be at least 18 years old or have reached the age of majority in your jurisdiction and a student of NITJ to use getKarma. By using the app, you confirm that you meet these requirements.
                </li>
                <li> User Responsibilities
                    <ul>
                        <li>
                        Account Security: You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account.
                        </li>
                        <li>
                        Compliance with Laws: You agree to comply with all applicable local, state, national, and international laws and regulations in connection with your use of the app.
                        </li>
                        <li>
                        User Content: Any content you submit, post, or display on the app (including delivery requests, reviews, and messages) must not violate any laws or the rights of third parties.
                        </li>
                    </ul>
                </li>
                <li> Delivery Services
                    <ul>
                        <li>
                        Nature of Service: getKarma facilitates connections between users for the purpose of completing deliveries. We do not provide delivery services ourselves and are not responsible for the actions of users who perform deliveries.
                        </li>
                        <li>
                        No Guarantees: We do not guarantee the availability, safety, or quality of any delivery services provided by users. You agree to use the app at your own risk.
                        </li>
                        <li>
                        User Transactions: Any transaction between users, including payments and exchanges of goods, is solely the responsibility of the users involved. We are not liable for any disputes arising from these transactions.
                        </li>
                    </ul>
                </li>
                <li> Payments and Credits
                    <ul>
                        <li>
                        Karma Credits: Users can earn Karma credits through deliveries. These credits have no monetary value and cannot be exchanged for cash.
                        </li>
                        <li>
                        Payments: Payments made should be handled by the users themselves, in case of unsuccessful delivery, users may refuse payment.
                        </li>
                        {/* <li>
                        Payments: Payments made through the app are processed by third-party payment providers. We are not responsible for any issues arising from payment processing, including errors or fraud.
                        </li> */}
                    </ul>
                </li>
                <li> Limitation of liability
                    <ul>
                        <li>
                        No Liability: To the fullest extent permitted by law, we disclaim all liability for any damages, losses, or injuries arising from your use of the app, including but not limited to direct, indirect, incidental, or consequential damages.
                        </li>
                        <li>
                        Indemnification: You agree to indemnify, defend, and hold us harmless from any claims, damages, or expenses arising out of your use of the app, your violation of these Terms, or your infringement of any rights of another.
                        </li>
                    </ul>
                </li>
                <li>
                Communication and Updates
                    <ul>
                        <li>
                        By creating an account on getKarma, you consent to receiving communications from us. These communications may include, but are not limited to, service-related announcements, promotional materials, updates, newsletters, customer support messages, and other forms of communication that are necessary to maintain and enhance the service provided by getKarma.
                        </li>
                        <li>
                        We may contact you via email, phone, text message, or other methods using the contact information you have provided during registration or while using our services. If you wish to opt out of receiving certain communications, you may do so by following the instructions provided in the communication or by adjusting your account settings.
                        </li>
                    </ul>


                </li>
                <li>
                    We reserve the right to suspend or terminate your access to the app at any time, for any reason, without notice. Upon termination, your right to use the app will immediately cease.
                </li>
                <li>
                    We may update these Terms from time to time. We will notify you of any significant changes by posting the new Terms on the app. Your continued use of the app after any changes constitutes your acceptance of the new Terms.
                </li>
                {/* <li>
                    These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from or related to these Terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
                </li> */}
                <li>
                    If you have any questions or concerns about these Terms, please contact us at <a href="mailto:nitj@getkarma.in" style={{textDecoration:'underline', color:'teal'}}>our email</a>.
                </li>
            </ul>
        </MainContainer>
    )
}