import { UITheme } from "./colors"

export function containerStyle(mobile) {
    const style = {
        margin: mobile?'0px auto':'25px auto',
        padding: mobile?'5px':'30px',
        width: mobile?'95vw':'80vw',
        backgroundColor: mobile?'':UITheme().panels,
        borderRadius: '5px',
        boxShadow: mobile?'':'0 4px 6px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.08)',
    }

    return style
}

export function heading() {
    const style = {
        margin: 'auto',
        textAlign: 'center',
        color: 'black',
        padding: '15px',
        fontSize: '2em',
        paddingBottom: '0.05em',
        userSelect: 'none',
        textWrap: 'nowrap',
    }

    return style
}

export function modalStyle() {
    const style = {
        backgroundColor: UITheme().panels,   
    }
    return style
}