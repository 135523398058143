import { Flex, Box, Input, FormLabel, FormControl, Button, useToast } from "@chakra-ui/react"
import { useState, useContext } from "react"
import { useNavigate } from "react-router"

import MainContainer from "../UI/MainContainer"
import vibFn from "../others/vibration"
import VPContext from "../others/VPContext"
import postData from "../../backend/postData"

export default function ResetPW() {
    const viewPort = useContext(VPContext)
    const toast = useToast()
    const navigate = useNavigate()

    function TheForm() {
        const [username, setUsername] = useState()
        const [email, setEmail] = useState()

        const formStyle = {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '15px',
            width: viewPort?'50%':'100%',
            margin: 'auto'
        }

        const handleEmail = (e) => setEmail(e.target.value)
        const handleUsername = (e) => setUsername(e.target.value)

        async function handleSubmit() {
            vibFn(3)
            const response = await postData('/api/sendpwrmail', {email: email, username: username})
            if(response.status==200){
                navigate(-1)
                toast({
                    title: 'Email Sent.',
                    description: "Kindly check your inbox and follow the steps to proceed...",
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })}
            else if(response.status==404)
                toast({
                    title: 'User not found.',
                    description: "Kindly check the credentials entered by you...",
                    status: 'warning',
                    duration: 4000,
                    isClosable: true,
                })
            else 
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while generating email, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
        }

        return (
            <>
            <FormControl style={formStyle}>
            <FormLabel>Enter your Roll No.</FormLabel>
            <Input textAlign={'center'} value={username} onChange={handleUsername} placeholder={'roll number'} />
            <FormLabel>Enter your College Email</FormLabel>
            <Input textAlign={'center'} value={email} onChange={handleEmail} placeholder={'email'} />
            <br />
            <Button colorScheme="blue" onClick={handleSubmit} >Generate Email</Button>
            </FormControl>
            </>
        )
    }

    return (
        <MainContainer nav heading='Reset Your Password'>
            <br />
            <TheForm/>
        </MainContainer>
    )
}