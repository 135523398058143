import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'

import VPContext from './components/others/VPContext.jsx'
import MenuContext from './components/others/MenuContext.jsx'

import App from './App';
import TopBar from './components/UI/navigation/TopBar.jsx'
import BottomBar from './components/UI/navigation/BottomBar.jsx'
import MenuOverlay from './components/UI/navigation/components/MenuOverlay.jsx';
import InstallSuggest from './components/elements/installSuggest.jsx';
import PushManager from './components/elements/pushManager.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));

function ViewPortManager(props) {
  let [width, setWidth] = useState(window.innerWidth>950?true:false)
  // true for Desktop, false for Mobile view

  setInterval(()=>{
    if(window.innerWidth > 950 && width === false) setWidth(true)
    else if(window.innerWidth < 950 && width === true) setWidth(false)
  },200)

  return (
    <>
    <VPContext.Provider value={width} >
      {props.children}
    </VPContext.Provider>
    </>
  )
}

function BarManager(props) {
  const viewPort = useContext(VPContext)
  const location = useLocation()
  const [barState, setBarState] = useState(0)
  
  const [overlayState, setOverlayState] = useState(false)
  const toggleOverlay = ()=>{
    setOverlayState(!overlayState)
  }

  useEffect(()=>{
    const curLoc = location.pathname.split('?')[0].split('/')[1] 
    const noBarPages = ['login', 'register', 'resetpw', 'setpw', 'qr', 'promo1', 'tnc']
    if(noBarPages.includes(curLoc)) {if(barState!==1) setBarState(1)}
      else {if(barState!==2) setBarState(2)}
  },[location, barState])

  return (
    <MenuContext.Provider value={{overlayState,toggleOverlay}} >
    {barState===2?
    <>
    {!viewPort?'':
      <PushManager/>
    }
    <TopBar/>
    {viewPort?'':
      <>
      <InstallSuggest/>
      <PushManager/>
      </>
    }
    </>
    :''}
    {
      overlayState&&
      <MenuOverlay/>
    }
    {props.children}
    {barState===2?<BottomBar/>:''}
    </MenuContext.Provider>
  )
}

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <div
        className='mainContainer'
      >
        <BrowserRouter>
        <ViewPortManager>
          <BarManager>
            <App/>
          </BarManager>
        </ViewPortManager>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
