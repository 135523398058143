import { Box, Text, useDisclosure, Modal, ModalOverlay, ModalBody, ModalContent, ModalHeader, 
    ModalCloseButton, ModalFooter, Button, Flex, Spinner, Skeleton } from '@chakra-ui/react'
import { Stepper, Step, StepIndicator, StepSeparator, StepTitle, StepDescription, Divider, useToast } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faLocationDot, faHouseUser, faPhone, faClock, 
    faIndianRupeeSign, faCubes, faDoorOpen, faPersonRunning, faIdCard, faTrash } from '@fortawesome/free-solid-svg-icons'

import postData from '../../backend/postData'
import vibFn from '../others/vibration'
import { useEffect, useState } from 'react'
import { modalStyle } from '../others/style'
    
const messageStyle={
    fontWeight:'500',
    color:'gray',
    textAlign:'center',
    fontStyle:'italic'
}

export function SelectCandidate(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [candidates, setCandidates] = useState()

    useEffect(onOpen,[onOpen])

    useEffect(()=>{
        const fetchCandidate = async()=>{
            const candidateInfo = await postData('/api/candidates', {id: props.request._id})
            if(candidateInfo.status !== 200) 
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while fetching data, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            else {
                setCandidates(candidateInfo.data.candidates)
            }
        }
        fetchCandidate()
    },[props.request._id, toast])

    const onClose2 = ()=> {vibFn(1);onClose(); props.fetchagain(); props.onclose()}

    function CandidateView(props) {
        const [candidateName, setCandidateName] = useState()

        const acceptButtonStyle = {
            borderRadius: '5px',
            cursor: 'pointer',
            padding: '20px',
            margin: '10px',
            backgroundColor: '#8F9779',
            width: '1em'
        }
        const rejectButtonStyle = {
            borderRadius: '5px',
            cursor: 'pointer',
            padding: '20px',
            margin: '10px',
            width: '1em',
            backgroundColor: '#c97a78'
        }
        
        useEffect(()=>{
            const fetchName = async()=>{
                const cdtName = await postData('/api/getName', {id: props.candidateid})
                if(cdtName.status === 200) setCandidateName(cdtName.data)
                    else setCandidateName('error...')
            }
            fetchName()
        },[props.candidateid])

        async function approveCandidate() {
            vibFn(1)
            const response = await postData('/api/approveCandidate', {
                requestid: props.requestid,
                candidateid: props.candidateid
            })
            if(response.status === 200) 
                toast({
                    title: 'Candidate Approved !',
                    description: "This candidate will now deliver your request...",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            else 
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while approving candidate, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            onClose2()
        }
            
        async function rejectCandidate() {
            vibFn(1)
            const response = await postData('/api/rejectCandidate', {
                requestid: props.requestid,
                candidateid: props.candidateid
            })
            if(response.status === 200) 
                toast({
                    title: 'Candidate Rejected.',
                    description: "This candidate will not deliver your request...",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            else 
                toast({
                    title: 'Error Encountered.',
                    description: "Error encountered while rejecting candidate, please try again later...",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            onClose2()
        }
            
        return (
            <>
            {
                // !candidateName?<Flex width={'100%'} justifyContent={'center'}><Spinner/></Flex>:
                <Skeleton isLoaded={candidateName}>
                <Flex flexDirection={'row'} borderRadius='5px' shadow='md' justifyContent={'space-between'} alignItems={'center'} backgroundColor='rgba(0,0,0,0.05)' >
                    <Flex flexDirection={'column'} padding={'10px'}>
                    <Text style={{fontWeight:'500', color:'black', fontSize:'large'}}>{candidateName?candidateName.name:''}</Text>
                    <Text style={{fontWeight:'400', color:'gray', fontSize:'small'}}>{candidateName?candidateName.username:''}</Text>
                    </Flex>
                    <Box>
                    <FontAwesomeIcon style={acceptButtonStyle} onClick={approveCandidate} color='white' icon={faCheck} />
                    <FontAwesomeIcon style={rejectButtonStyle} onClick={rejectCandidate} color='white' icon={faXmark} />
                    </Box>
                </Flex>
                </Skeleton>
            }
            </>
        )
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose2} isCentered motionPreset='slideInBottom'>
                <ModalOverlay style={{backdropFilter:'blur(5px)'}} />
                <ModalContent style={modalStyle()}>
                <ModalHeader textAlign={'center'}>Approve for delivery</ModalHeader>
                <ModalCloseButton />
                <Box style={{maxHeight:'65vh', overflowY:'auto'}}>
                <ModalBody>
                    {
                        candidates ?
                        candidates.map((candidate, key)=>(
                            <CandidateView key={key} candidateid={candidate.candidateid} requestid={candidate.requestid} />
                        )) :
                        <Skeleton height={'100px'} />
                    }
                </ModalBody>
                </Box>

                <ModalFooter>
                    <Flex flexDirection={'column'} width='100%' alignItems={'center'} >
                        <Text style={messageStyle}>Approve a user to deliver your item...</Text>
                        <Flex>
                            <Button colorScheme='blackAlpha' mr={3} onClick={onClose2}>
                                Close
                            </Button>
                        </Flex>
                    </Flex>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export function ViewMyRequest(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [userData, setUserData] = useState()

    async function withdrawRequest() {
        const response = await postData('/api/withdrawRequest', {id:props.request._id})
        if(response.status === 200) 
            toast({
                title: 'Request Deleted.',
                description: "This request has been deleted and your karma refunded (if applicable)...",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while deleting request, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        props.fetchagain()
        onClose2()
    }

    async function completeRequest() {
        const response = await postData('/api/completeRequest', {id:props.request._id})
        if(response.status === 200) 
            toast({
                title: 'Completion Initiated !',
                description: "Continue compleition on the delivery person end, to mark request as fulfilled...",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        else 
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while completing request, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        props.fetchagain()
        onClose2()
    }

    useEffect(()=>{
        const fetchUser = async()=>{
            if (props.request.takerid){
            const fetchedUser = await postData('/api/userdataById', {id: props.request.takerid})
            if(fetchedUser.status === 200) setUserData(fetchedUser.data)
                else {
            toast({
                title: 'Error Encountered.',
                description: "Error encountered while fetching data, please try again later...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })}
        }}
        fetchUser()
    },[props.request.takerid, toast])

    useEffect(onOpen,[onOpen])

    const onClose2 = ()=> {vibFn(1);onClose(); props.onclose()}

    function formatMsToHoursAndMinutes(ms) {
        ms -= Date.now()
        if(ms<0) return 'Time finished'
        const totalMinutes = Math.floor(ms / 60000);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        const formattedTime = `${hours}h ${minutes}min`;
        return formattedTime;
    }

    function DataSpread(props) {
        return (
            <Flex flexDirection={'row'}>
            <Text style={{boxSizing:'border-box', width:'100%',
            color:'gray', fontWeight: '500', fontSize:'large'}}>{props.left}</Text>
            -
            <Text style={{boxSizing:'border-box', width:'100%', textAlign:'right'}}>{props.right}</Text>
            </Flex>
        )
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose2} isCentered motionPreset='slideInBottom'>
                <ModalOverlay style={{backdropFilter:'blur(5px)'}} />
                <ModalContent style={modalStyle()}>
                <ModalHeader textAlign={'center'}>View Request</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stepper colorScheme='green' index={(props.request.status !== 'complete')?'2':'3'}>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faLocationDot} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.request.location}</StepTitle>
                                <StepDescription>Pickup Location</StepDescription>
                            </Box>
                        </Step>
                        <StepSeparator/>
                        <Step>
                            <StepIndicator>
                                <FontAwesomeIcon icon={faHouseUser} />
                            </StepIndicator>
                            <Box flexShrink='0'>
                                <StepTitle>{props.request.hostel}</StepTitle>
                                <StepDescription>Delivery Location</StepDescription>
                            </Box>
                        </Step>
                    </Stepper>
                    <br /><Divider/><br />
                    <Box>
                        <DataSpread left={<><FontAwesomeIcon icon={faCubes} /> Item</>} right={props.request.item} />
                        <DataSpread left={<><FontAwesomeIcon icon={faIndianRupeeSign} /> Cost</>} right={props.request.cost} />
                        {
                            props.request.status==='complete'?'':
                            <DataSpread left={<><FontAwesomeIcon icon={faClock} /> Time</>} right={formatMsToHoursAndMinutes(props.request.time)} />
                        }
                            <DataSpread left={<><FontAwesomeIcon icon={faDoorOpen} /> Room Number</>} right={props.request.room} />
                        {
                            props.request.status==='awaited'||props.request.status==='free'?'':
                            <>
                            <Skeleton isLoaded={userData} >
                                <DataSpread left={<><FontAwesomeIcon icon={faPersonRunning} /> Delivery By</>} right={userData?userData.name:<Spinner/>} />
                            </Skeleton>
                            <Skeleton isLoaded={userData}>
                                <DataSpread left={<><FontAwesomeIcon icon={faIdCard} /> Roll Number</>} right={userData?userData.username:<Spinner/>} />
                            </Skeleton>
                            </>
                        }
                        {
                            !(props.request.status === 'busy'||props.request.status === 'completing')?'':
                            <Skeleton isLoaded={userData} >
                                <DataSpread left={<><FontAwesomeIcon icon={faPhone} /> Phone</>} right={userData?<a style={{textDecoration:'underline', color:'blue'}} href={'tel:'+userData.phone}>{userData.phone}</a>:<Spinner/>} />
                            </Skeleton>
                        }
                        <br />
                        {
                            props.request.status!=='free'?'':
                            <Text style={messageStyle} >Your request has not been accepted by anyone yet...</Text>
                        }
                        {
                            props.request.status!=='awaited'?'':
                            <Text style={messageStyle} >You have delivery offers available, accept them to get your item delivered...</Text>
                        }
                        {
                            props.request.status!=='busy'?'':
                            <Text style={messageStyle} >Your request will be fulfilled soon...</Text>
                        }
                    </Box>
                </ModalBody>

                <ModalFooter>
                   <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width='100%'>
                        <Flex width='100%' justifyContent='space-evenly' >
                            <Button colorScheme='blackAlpha' mr={3} onClick={onClose2}>
                                Close
                            </Button>
                            {!(props.request.status === 'free'||props.request.status === 'awaited')?'':
                                <Button colorScheme='red' onClick={withdrawRequest} 
                                rightIcon={<FontAwesomeIcon icon={faTrash} />}>Withdraw</Button>
                            }
                            {props.request.status === 'busy'?
                                <Button colorScheme='blue' onClick={completeRequest}>Delivery Recieved <FontAwesomeIcon style={{paddingLeft:'5px'}} icon={faCheck} /></Button>
                            :''}
                            {props.request.status === 'completing'?
                                <Button colorScheme='orange' isDisabled >{'Verifying'}<Box paddingLeft='5px'><Spinner/></Box></Button>
                            :''}
                        </Flex>
                        <Text style={messageStyle}>{(
                            (props.request.status !== 'awaited'&&props.request.status !== 'free')?(
                                props.request.status !== 'busy'?
                                (
                                    props.request.status==='complete'?
                                    'This item was delivered to you in the past...':
                                    'Confirming delivery status from delivery person...'
                                ):
                                <>
                                Make a payment of ₹{(Number(props.request.cost) + Number(props.request.payMode?props.request.payMode:0))+' '}
                                at the time of delivery...
                                <br />
                                <span style={{color:'red', fontWeight:'400'}}>
                                <u>Disclaimer:</u> Please check the items before paying the delivery person.
                                </span>
                                </>
                            )
                            :''
                        )}</Text>
                    </Flex>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}