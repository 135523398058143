import { useState, useEffect, useContext } from 'react'
import { Text, Center, Heading, Flex, Skeleton, HStack } from '@chakra-ui/react'
import { SunriseIcon, Sun03Icon, SunCloud02Icon, MoonSlowWindIcon } from 'hugeicons-react'

import postData from '../../backend/postData'
import VPContext from '../others/VPContext'

import Hero from '../UI/Hero'
import MainContainer from '../UI/MainContainer'
import { RequestItemButton, DeliverItemButton, EarnButton } from '../elements/accentButtons'

const getUser = async (setUser)=>{
    const getUser = await postData('/api/userdata')
    if (getUser) setUser(getUser)
}

function Salutation(props){
    const viewPort = useContext(VPContext)

    const time = new Date()
    const hours = time.getHours()

    function salutation(t){
        if(t>4&&t<12) return 'Morning'
        else if(t>11&&t<17) return 'Afternoon'
        else return 'Evening'
    }

    function SkyIcon() {
        if(hours>4&&hours<12) return <SunriseIcon color='gray' size='4em' variant='outline' />
        else if(hours>11&&hours<17) return <Sun03Icon color='gray' size='4em' variant='outline' />
        else if(hours>16&&hours<21) return <SunCloud02Icon color='gray' size='4em' variant='outline' />
        else return <MoonSlowWindIcon color='gray' size='4em' variant='outline' />
    }

    return (
        <HStack justify='center'>
        <SkyIcon/>
        <Heading color='gray' textAlign={'center'}>Good {salutation(hours)} {viewPort?'':<br/>} {props.name}...</Heading>
        </HStack>
    )
}

function HomePage() {
    let [user, setUser] = useState()

    useEffect(()=>{getUser(setUser)},[])
    
    return (
        <>
        <Hero/>
        <MainContainer>
            <Skeleton fadeDuration={2} isLoaded={user}>
                <Salutation name={!user?'':user.data.name} />
                <Text textAlign={'center'} fontWeight={'500'}>You have {!user?'':user.data.credits} karma credits</Text>
            </Skeleton>
            <br />
            <Flex flexWrap={'wrap'} gap='15px' justifyContent={'space-evenly'}>
                <RequestItemButton/>
                <DeliverItemButton/>
                <EarnButton/>
            </Flex>
            <br />
            <Center style={{width:'100%'}} textAlign={'center'}>
                <a href="mailto:nitj@getkarma.in" style={{color:'teal', textDecoration:'underline'}}>Contact Us</a>
            </Center>
        </MainContainer>
        </>
    )
}

export default HomePage