import { useState, useContext } from "react";
import { Text, FormControl, FormLabel, Input, Button, Box, Flex, useToast, Spinner, Center, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import login from "../../backend/login";
import VPContext from "../others/VPContext";
import vibFn from "../others/vibration";

import MainContainer from "../UI/MainContainer";
import favicon from '../../media/favicon.png'
import { UITheme } from "../others/colors";

function LoginForm() {

    let [formData, setFormData] = useState({
        username: '',
        password: ''
    })
    const [buttonStatus, setButtonStatus] = useState(true)
    const toast = useToast()
    let viewPort = useContext(VPContext)

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        width: viewPort?'50%':'100%',
        margin: 'auto'
    }

    function handleChange(e) {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    async function submitForm(){
        vibFn(1)
        setButtonStatus(false)
        if(formData.username && formData.password) {
            const loginStatus = await login(formData)
            if(loginStatus) window.location.href = '/'
            else{
            vibFn(4)
            setButtonStatus(true)
            toast({
                title: 'Incorrect credentials.',
                description: "The credentials entered by you are incorrect, please try again...",
                status: 'error',
                duration: 2000,
                isClosable: true,
            })}
        }
        else{
            setButtonStatus(true)
            toast({
                title: 'Fill the login credentials.',
                description: "Kindly enter your roll number and password to continue...",
                status: 'warning',
                duration: 4000,
                isClosable: true,
            })
        }
        // setFormData({username:'', password:''})
    }

    return (
        <>
        <Box shadow={viewPort?'':'lg'} style={viewPort?{}:{
            backgroundColor:"#f5fdfd",
            borderRadius:"25px",
            padding:'5px'
        }}>
        <Text style={{fontSize:'1.8rem', fontWeight:'700', textAlign:'center'}}>Login to your Account</Text>
        <FormControl style={formStyle} mt={4}>
            {/* <Text color='red'>[ALPHA - PRE_RELEASE]</Text>
            <Text color='red'>[FOR TESTING PURPOSES ONLY]</Text> */}
            <Text color='teal'>[ BETA ]</Text>
            <FormLabel>Roll Number</FormLabel>
            <Input
            type="number"
            textAlign={'center'}
            placeholder='roll number'
            id='username'
            value={formData.username}
            onChange={handleChange}
            />
            <FormLabel>Password</FormLabel>
            <Input
            textAlign={'center'}
            placeholder='password'
            id='password'
            type='password'
            value={formData.password}
            onChange={handleChange}
            />
        <Flex flexDirection={'column'} gap='0' justifyContent={'center'} alignItems={'center'}>
            <Button isDisabled={!buttonStatus} margin='15px' rightIcon={!buttonStatus&&<Spinner/>} colorScheme='blue' onClick={submitForm}>LOGIN</Button>
            <Link style={{color:'gray', textDecoration:'underline'}} onClick={()=>vibFn(1)} to='/resetpw' >
            Forgot Password ?</Link>
        </Flex>
        </FormControl>
        </Box>
        {viewPort?'':<br/>}
        </>
    )
}

async function handleAutoLogin_Fetch() {
    try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/validateToken', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
        if (response.status === 200) {
            const validity = await response.json()
            if(!validity) return false
            if(!validity.validity) return false
            return true
        }
        else return false
    }
    catch(e) {return e}
}

async function handleAutoLogin() {
    const autoLoginStatus = await handleAutoLogin_Fetch()
    if(autoLoginStatus) {
        console.log('Logged in automatically !')
        window.location.href = '/'
    }
    else console.log('No saved login found.')
}

function Graphics() {
    let viewPort = useContext(VPContext)
    return (
        <>
        <br />
        <Center flexDirection={viewPort?'row':'column'}>
        <Image src={favicon} width={viewPort?'10vw':'20vw'} alt={'getKarma'} />
        <TextC/>
        </Center>
        </>
    )
}

function TextC() {
    const viewPort = useContext(VPContext)

    const headingStyle = {
        fontSize:'2em',
        fontWeight: '700',
        color: UITheme(!viewPort).navBar,
    }
    const subHeadingStyle = {
        fontSize:'1.6em',
        fontWeight: '500',
        color:'black',
        textWrap: 'nowrap',
        fontFamily: 'Allura',
    }
    return (
        <Flex style={{flexDirection:'column', alignItems:'center', justifyContent:"center"}}>
            <Text style={headingStyle}>
                getKarma
            </Text>
            <Text style={subHeadingStyle}>
                more than just a delivery app
            </Text>
        </Flex>
    )
}

function LoginPage() {

    const viewPort = useContext(VPContext)
    setTimeout(handleAutoLogin)
    
    return (
        <Box style={viewPort?{}:{
            backgroundColor:"rgb(231, 245, 248)",
            height:"100%",
        }}>
        <Graphics/>
        <MainContainer>
            <LoginForm/>
            <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Text><em>Dont have an account yet ?</em></Text>
            <Link to={'/register'}>
                <Button colorScheme="green" onClick={()=>vibFn(1)} >Create an account</Button>
            </Link>
            </Flex>
            <br />
            <Center style={{width:'100%'}} textAlign={'center'}>
                <a href="mailto:nitj@getkarma.in" style={{color:'teal', textDecoration:'underline'}}>Contact Us</a>
            </Center>
        </MainContainer>
        </Box>
    )
}

export default LoginPage