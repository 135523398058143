import { Box, Text, Flex, Skeleton, SkeletonText, useToast } from '@chakra-ui/react'
import { useEffect, useState, useContext } from 'react'
import {
    Accordion, AccordionItem, AccordionIcon, AccordionButton,
    AccordionPanel, Spinner
} from '@chakra-ui/react'
import Lottie from 'lottie-react'
import { motion } from 'framer-motion'

import postData from '../../backend/postData'

import MainContainer from '../UI/MainContainer'
import VPContext from '../others/VPContext'
import vibFn from '../others/vibration'


import { Cards1, Cards4 } from '../elements/cards'
import { DeliverItemButton } from '../elements/accentButtons'
import cards from '../../media/animations/cards.json'
import emptyBox from '../../media/animations/emptyBox.json'

function MyErrands() {

    const [errands, setErrands] = useState()
    const [candidateReqs, setCandidateReqs] = useState()
    const toast = useToast()
    const viewPort = useContext(VPContext)

    async function fetchErrands() {
        const response = await postData('/api/errands')
        if(response.status == 200) setErrands(response.data.errands)
        else
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while fetching data, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }
    async function fetchCandidate() {
        const response = await postData('/api/candidatesByUser')
        if(response.status == 200) setCandidateReqs(response.data.req)
        else
        toast({
            title: 'Error Encountered.',
            description: "Error encountered while fetching data, please try again later...",
            status: 'error',
            duration: 2000,
            isClosable: true,
        })
    }

    useEffect(()=>{
        fetchErrands(); fetchCandidate()
    },[])

    function filterFn(errnds, type){
        return errnds.filter((err)=>err.status == type)
    }

    function NoErrandFound(props){
        const messageStyle = {
            color: 'gray',
            fontSize: 'medium',
            fontWeight: '600'
        }
        return (
            <>
            <Flex flexDirection={viewPort?'row':'column'} alignItems={'center'} justifyContent={'center'}>  
                <Flex backgroundImage='radial-gradient(circle at center, rgba(138,141,143,0.25),transparent, transparent)' width={viewPort?'25vw':'43vw'}>
                    <Lottie animationData={props.anim} />
                </Flex>
                <Flex flexDirection={'column'} gap='10px' alignItems={'center'} justifyContent={'center'}>
                    <Text style={messageStyle}>{props.text}</Text>
                {
                    !props.button?'':
                    <DeliverItemButton/>
                }
                </Flex>
            </Flex>
            </>
        )
    }

    return (
        <MainContainer heading='My Errands'>
            <br /><br />
            <Box>
                {
                    (candidateReqs && candidateReqs.length) ? (
                        <motion.div
                            initial={{ scale: 0, y:'-100%', margin:'auto', opacity:1, borderRadius:'100% /100%', overflow:'hidden'}}
                            animate={{ scale: 1, y:0, opacity: 1, borderRadius: 0 }}
                            transition={{ duration: 0.25 }}
                        >
                            <Text fontWeight={'500'}>
                                Waiting for approval...
                            </Text>
                            {candidateReqs.map((errand, key)=>(
                                <Cards4 type='myErrands' key={key} fetchagain={async()=> {await fetchErrands(); await fetchCandidate()}} data={errand} />
                            ))}
                        </motion.div>
                    ):
                    // <Skeleton height={'100px'} />
                    ''
                }
                {/* <motion.div layout> */}
                <Accordion defaultIndex={[0]}>
                    <AccordionItem>
                        <AccordionButton onClick={()=>vibFn(2)} style={{color:'black', justifyContent:'space-between'}}>
                            <Text fontWeight={'500'}>
                                Ongoing Errands
                            </Text>
                            <AccordionIcon/>
                        </AccordionButton>
                        <AccordionPanel>
                            {errands ? ( ![...filterFn(errands, 'completing'), ...filterFn(errands, 'busy')].length ? <NoErrandFound button text='You do not have any Errands going on...' anim={cards} /> 
                            : [...filterFn(errands, 'completing'), ...filterFn(errands, 'busy')].map((errand, key)=>(
                                <Cards1 type='myErrands' key={key} fetchagain={async()=> {await fetchErrands(); await fetchCandidate()}} data={errand} />
                            ))):
                            <Skeleton height={'200px'} />
                            }
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton onClick={()=>vibFn(2)} style={{color:'black', justifyContent:'space-between'}}>
                            <Text fontWeight={'500'}>
                                Completed Errands
                            </Text>
                            <AccordionIcon/>
                        </AccordionButton>
                        <AccordionPanel>
                            {errands ? ( !filterFn(errands, 'complete').length? <NoErrandFound text='You have not completed any deliveries in the past...' anim={emptyBox} /> 
                            : filterFn(errands, 'complete').map((errand, key)=>(
                                <Cards1 type='myErrands' key={key} fetchagain={async()=> {await fetchErrands(); await fetchCandidate()}} data={errand} />
                            ))):
                            <Skeleton height={'200px'} />}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                {/* </motion.div> */}
            </Box>
        </MainContainer>
    )
}

export default MyErrands