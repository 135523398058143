import React from "react";
import "./StarryBackground.css";

const StarryBackground = () => {
  const stars = Array.from({ length: 100 }, (_, i) => {
    const top = Math.random() * 100;
    const left = Math.random() * 100;

    return (
      <div
        key={i}
        className="star"
        style={{ top: `${top}%`, left: `${left}%` }}
      ></div>
    );
  });

  return <div className="starry-background">{stars}</div>;
};

export default StarryBackground;