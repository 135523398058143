import { useState, useEffect } from "react";
import { Button, HStack, Text, Box, Flex } from "@chakra-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import vibFn from "../others/vibration";
import postData from "../../backend/postData";

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
    
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export default function PushManager() {
    const [panelState, setPanelState] = useState(0)
    // 0 default
    // 1 for not supported
    // 2 for ask permission
    // 3 for all set up
    // 5 for denied (crybaby mode)

    async function setUpPush() {
        if (!("Notification" in window)) {
            setPanelState(1)
          } else if (Notification.permission === "granted") {

            if (!('serviceWorker' in navigator)) {
                setPanelState(1)
            }
            else {
                try {
                    // Register Service Worker
                    const register = await navigator.serviceWorker.register("worker.js", {
                    scope: "/"
                    });

                    const subscription = await register.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_PUBLICVAPIDKEY)
                    });
                    
                    await postData('/api/subscribe', subscription)
                    
                    if(panelState!==3) setPanelState(3)

                } catch(e) {setPanelState(2);console.log(e)}
            }

        } else if (Notification.permission !== "denied") {
            setPanelState(2)
        } else if (Notification.permission === 'denied') setPanelState(5)
    }

    useEffect(()=>{
        setUpPush()
    },[panelState])

    function ObtainPerm() {
        function askPermission() {
            return new Promise(function (resolve, reject) {
              const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
              });
          
              if (permissionResult) {
                permissionResult.then(resolve, reject);
              }
            }).then(function (permissionResult) {
              if (permissionResult !== 'granted') {
                // throw new Error("We weren't granted permission.");
                vibFn(4)
                setPanelState(5)
              } else {
                vibFn(3)
                setPanelState(3)
              }
            });
          }

        return (
            <HStack
                style={{
                    width:'100%',
                    justifyContent:'space-evenly',
                    backgroundColor:'#f47174',
                    padding:'5px 30px 5px 30px',
                    fontWeight:'700',
                    color:'white'
                }}
            >
                <p>Turn on Push Notifications</p>
                <Button onClick={askPermission} colorScheme='whiteAlpha'>Allow</Button>
            </HStack>
        )
    }

    function CryBaby() {
        const [mState, setMState] = useState(
            window.sessionStorage.cb?(window.sessionStorage.cb==='true'?true:false):true
        )

        if(mState) return (
            <Flex style={{backgroundColor:'red', color:"white", gap:'10px',
                textAlign:'center',textJustify:'auto' , fontWeight:'700', 
                alignItems:'center', justifyContent:'space-evenly', padding: '2px 7px 2px 7px'}}>
            <Text>
                KINDLY GRANT NOTIFICATION PERMISSION FOR A BETTER EXPERIENCE
            </Text>
            <Box onClick={()=>{vibFn(1);setMState(false); window.sessionStorage.cb='false'}}>
                <FontAwesomeIcon style={{cursor:'pointer'}} size='2x' icon={faXmark} />
            </Box>
            </Flex>
        ) 
        else return
    }

    if(panelState===2) 
        return <ObtainPerm/>
    else if(panelState===5)
        return <CryBaby/>
}